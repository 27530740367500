<template>
  <div>
    <!-- {{ adminFolderState.focusFolderData }} -->
    <div class="list-area animate__animated animate__fadeIn">
      <b-table
        v-if="adminFolderState.currentFolder.length > 0"
        ref="refDataListTable"
        v-contextmenu:gallery-menu-action
        :items="currentFolderFilter"
        :fields="tableColumnFilter"
        variant="primary"
        responsive
        hover
        striped
        show-empty
        :tbody-tr-class="rowClass"
        class="position-relative"
        :stacked="$store.getters['app/currentBreakPoint'] !== 'lg' && $store.getters['app/currentBreakPoint'] !== 'xl'"
        @row-clicked="(item, index, $event) => setFocusFolderData($event, item)"
        @row-contextmenu="(item, index, $event) => setRightClickData($event, item)"
      >
        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <template #cell(selected)="data">
          <template v-if="isFolderInSelected(data.item)">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>

        <!-- 名稱 -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <span
              v-if="data.item.type === 'folder'"
            >
              <!-- @click="handleClickOpenFolder(data.item)" -->
              <FolderImage
                :size="30"
                close-folder-image="/admin/images/file/folder/close-folder-1.svg"
                open-folder-image="/admin/images/file/folder/open-folder.svg"
              />
            </span>

            <span
              v-else
            >
              <!-- @click="handleClickOpenFolder(data.item)" -->
              <div class="component-folder-image">
                <b-img
                  :draggable="false"
                  :src="resolveFileTypeImage(data.item).image"
                  :alt="resolveFileTypeImage(data.item).name"
                />
              </div>
            </span>

            <div class="ml-1">
              <div
                v-if="!isFileTypeSame(adminFolderState.renameFolderData, data.item)"
                @click="handleClickEditName(data.item)"
              >
                {{ resolveFileNameLong(data.item.name, 10) }}
              </div>

              <div
                v-else
                class="title-input"
              >
                <b-form-input
                  v-model="adminFolderState.renameFolderData.newName"
                  size="sm"
                  :placeholder="data.item.name"
                  autofocus
                  @keydown.enter.prevent="submitConfirmName"
                />
              </div>
            </div>
          </div>
        </template>

        <!-- 修改日期 -->
        <template #cell(updated_at)="data">
          <div class="tr-selected">
            {{ data.item.updated_at ? moment(data.item.updated_at).format('YYYY-MM-DD HH:mm') : '---' }}
          </div>
        </template>

        <!-- 類型 -->
        <template #cell(type)="data">
          <div>
            <div v-if="data.item.type === 'folder'">
              檔案資料夾
            </div>

            <div v-else>
              {{ data.item.extension ? `${data.item.extension.toUpperCase()}` : '未知' }}檔案
            </div>
          </div>
        </template>

        <!-- 大小 -->
        <template #cell(size)="data">
          <div>
            <div v-if="data.item.type === 'folder'">
              ---
            </div>

            <div v-else>
              {{ convertBytes(data.item.size) }}
            </div>
          </div>
        </template>
      </b-table>

      <div
        v-if="adminFolderState.currentFolder.length === 0"
        class="d-flex justify-content-center w-100 p-2"
      >
        <span>這個資料夾是空的</span>
      </div>
    </div>

    <v-contextmenu
      ref="gallery-menu-action"
      :class="$store.state.appConfig.layout.skin === 'dark' ? 'my-contextmenu-dark' : 'my-contextmenu'"
    >
      <div>
        <v-contextmenu-item
          class="dropdown-item-area"
          @click="submitShowFileInfo(rightClick)"
        >
          <b-img
            src="/admin/images/file/action/info.svg"
            class="dropdown-item-btn-image mr-50"
          />
          <span>顯示詳情</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          v-if="rightClick.type !== 'folder'"
          class="dropdown-item-area"
          @click="submitDownloadFile(rightClick)"
        >
          <b-img
            src="/admin/images/file/action/download.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>下載</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          class="dropdown-item-area"
        >
          <b-img
            src="/admin/images/file/action/scissors.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>剪下</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          class="dropdown-item-area"
          @click="submitRenameFolder"
        >
          <b-img
            src="/admin/images/file/action/rename.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>重新命名</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          class="dropdown-item-area"
          @click="submitDeleteFolder"
        >
          <b-img
            src="/admin/images/file/action/trash-bin.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>刪除</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          class="dropdown-item-area"
          @click="submitSelectedFolder(rightClick)"
        >
          <b-img
            src="/admin/images/file/action/check1.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>
            <span>{{ isFolderInSelected(rightClick) ? '取消選取' : '選取檔案' }}</span>
          </span>
        </v-contextmenu-item>

      </div>
    </v-contextmenu>

    <!-- 編輯提示彈窗 -->
    <b-modal
      id="folderEditModal"
      no-close-on-backdrop
      centered
      hide-header
    >
      <div class="p-1">
        <h4>是否將{{ adminFolderState.renameFolderData.type === 'folder' ? '資料夾' : '檔案' }}的名稱變更為 {{ adminFolderState.renameFolderData.newName }} 嗎?</h4>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="closeEditModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isBusy"
            @click="confirmEditModal"
          >
            確認
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- 刪除提示彈窗 -->
    <b-modal
      id="folderDeleteModal"
      no-close-on-backdrop
      centered
      hide-header
    >
      <div class="p-1">
        <h4>你想要刪除{{ rightClick.type === 'folder' ? '資料夾' : '檔案' }} {{ rightClick.name }} 嗎?</h4>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="closeDeleteModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isBusy"
            @click="confirmDeleteModal"
          >
            刪除
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {
  BImg, BButton, BModal, BTable, BFormInput,
} from 'bootstrap-vue'
import moment from 'moment'
import FolderImage from './FolderImage.vue'
import { useFolderList, useFolderSetting, useFolderMixins } from '../useFolder'

export default {
  components: {
    BImg,
    BButton,
    BModal,
    BTable,
    BFormInput,

    FolderImage,
  },
  mixins: [useFolderMixins],
  data() {
    return {
      hoverItemId: null,
      rightClick: {},
      clickFolderTimeout: null, // 用于记录第一次点击后的超时时间
      clickNameTimeout: null,
    }
  },
  computed: {
    adminFolderState() {
      return this.$store.state['admin-folder']
    },
    currentFolderFilter() {
      const adminFolderState = this.$store.state['admin-folder']
      const searchKeyword = adminFolderState.searchQuery ? adminFolderState.searchQuery.toLowerCase() : adminFolderState.searchQuery
      if (searchKeyword) return adminFolderState.currentFolder.filter(f => !f.name || f.name.includes(searchKeyword.toLowerCase()))
      return adminFolderState.currentFolder
    },
    tableColumnFilter() {
      if (this.adminFolderState.selectedMutiFolders.length > 0) {
        return [
          { label: '選擇', key: 'selected', sortable: false },
          { label: '名稱', key: 'name', sortable: true },
          { label: '修改日期', key: 'updated_at', sortable: true },
          { label: '類型', key: 'type', sortable: true },
          { label: '大小', key: 'size', sortable: true },
        ]
      }
      return [
        { label: '名稱', key: 'name', sortable: true },
        { label: '修改日期', key: 'updated_at', sortable: true },
        { label: '類型', key: 'type', sortable: true },
        { label: '大小', key: 'size', sortable: true },
      ]
    },
  },
  methods: {
    moment,
    rowClass(item) {
      const focusItem = this.adminFolderState.focusFolderData
      if (!focusItem || !focusItem.id) return null
      if (item.id === focusItem.id && item.type === focusItem.type) return 'custom-bg-color'
      return null
    },
    // KB轉換
    convertBytes(bytes) {
      const kilobytes = (bytes / 1024).toFixed(1)
      const gigabytes = (kilobytes / (1024 * 1024)).toFixed(1)
      const megabytes = (kilobytes / 1024).toFixed(1)

      if (kilobytes >= 1024 * 1024) return `${gigabytes.toLocaleString()} GB`
      if (kilobytes >= 1024) return `${megabytes.toLocaleString()} MB`
      return `${kilobytes.toLocaleString()} KB`
    },

    // (顯示)檔案字數
    resolveFileNameLong(text, length) {
      if (text.length > length * 2) {
        const start = text.slice(0, length)
        const end = text.slice(-6)
        return `${start}...${end}`
      }
      return text
    },
    // (測試)圖片網址(未使用)
    testImageUrl(item) {
      const img = new Image()
      img.src = this.resolveFileTypeImage(item).image

      return new Promise(resolve => {
        img.onload = () => {
          resolve(true)
        }

        img.onerror = () => {
          resolve(false)
        }
      })
    },

    // (顯示)檔案類型
    resolveFileTypeImage(item) {
      const matchingItem = this.folderType.find(el => el.type === item.extension)
      if (item.type === 'file' && item.preview_image) {
        matchingItem.image = item.preview_image
      }
      if (matchingItem) return matchingItem
      return { name: '未知', type: null, image: '/admin/images/file/type/empty.svg' }
    },

    // (是否)選取
    isFolderInSelected(item) {
      const { selectedMutiFolders } = this.adminFolderState
      const index = selectedMutiFolders.findIndex(el => el.id === item.id && el.type === item.type)

      if (index !== -1) return true
      return false
    },

    // (觸發)選取檔案
    submitSelectedFolder(item) {
      // console.log('選取', item)
      this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', null)
      this.$store.commit('admin-folder/SELECTED_MUTI_FOLDERS', item)
    },

    // (點擊)左鍵
    setFocusFolderData(event, item) {
      // console.log('左鍵', item, index)
      event.stopPropagation()
      if (this.adminFolderState.selectedMutiFolders.length > 0) {
        this.submitSelectedFolder(item)
        return
      }
      this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', item)
      this.handleClickOpenFolder(item)
    },

    // (點擊)右鍵
    setRightClickData(event, item) {
      event.preventDefault()
      this.rightClick = item
    },

    // (雙擊)打開資料夾
    handleClickOpenFolder(item) {
      // console.log('雙擊')
      if (this.clickFolderTimeout && this.adminFolderState.focusFolderData.id === item.id) {
        clearTimeout(this.clickFolderTimeout)
        this.clickFolderTimeout = null
        if (this.adminFolderState.focusFolderData.type === 'folder') {
          this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', null)
          this.getOpenFolderChildren(item, () => {
            // this.resetComponentData()
            this.$store.commit('admin-folder/OPEN_LOCAL_FOLDER', item)
            this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
          })
        }
      } else {
        this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', item)
        this.clickFolderTimeout = setTimeout(() => {
          this.clickFolderTimeout = null
        }, 300)
      }
    },

    // (雙擊)編輯名稱
    handleClickEditName(item) {
      this.clickFolderTimeout = null
      if (this.clickNameTimeout && (this.adminFolderState.focusFolderData && this.adminFolderState.focusFolderData.id === item.id)) {
        clearTimeout(this.clickNameTimeout)
        const nameData = {
          id: item.id,
          type: item.type,
          newName: item.name,
        }
        this.$store.commit('admin-folder/UPDATE_RENAME_FOLDER_STATE', nameData)
      } else {
        this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', item)
        this.clickNameTimeout = setTimeout(() => {
          this.clickNameTimeout = null
        }, 300)
      }
    },
    // ------------------------------------------------------------------------------------------
    // 下載
    // ------------------------------------------------------------------------------------------
    // (觸發)下載
    submitDownloadFile(rightClick) {
      this.getFileDownload(rightClick, url => {
        const link = document.createElement('a')
        link.href = url
        link.click()
      })
    },

    // (觸發)顯示詳情
    submitShowFileInfo(rightClick) {
      this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', rightClick)
      this.$emit('submit-open-application-info')
    },
    // ------------------------------------------------------------------------------------------
    // 編輯
    // ------------------------------------------------------------------------------------------
    // (點擊動作)重新命名
    submitRenameFolder() {
      const nameData = {
        id: this.rightClick.id,
        type: this.rightClick.type,
        newName: this.rightClick.name,
      }
      this.$store.commit('admin-folder/UPDATE_RENAME_FOLDER_STATE', nameData)
    },

    // (觸發)編輯名稱
    submitConfirmName() {
      if (!this.adminFolderState.renameFolderData.id) {
        this.adminFolderState.renameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
        return
      }
      this.$bvModal.show('folderEditModal')
    },

    // (觸發)關閉編輯
    closeEditModal() {
      // this.adminFolderState.renameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
      // this.$bvModal.hide('folderEditModal')
      const resolveRenameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
      this.$bvModal.hide('folderEditModal')
      this.$store.commit('admin-folder/UPDATE_RENAME_FOLDER_STATE', resolveRenameFolderData)
    },

    // (編輯)檔案
    confirmEditModal() {
      this.isBusy = true
      this.setFolderUpdate(() => {
        const resolveListData = this.adminFolderState.currentFolder.map(item => {
          if (item.id === this.adminFolderState.renameFolderData.id && item.type === this.adminFolderState.renameFolderData.type) {
            return {
              ...item,
              name: this.adminFolderState.renameFolderData.newName,
              updated_at: new Date(),
            }
          }
          return item
        })

        // (修改)側欄結構
        if (this.adminFolderState.renameFolderData.type === 'folder') {
          this.refreshSidebarList('update', { ...this.adminFolderState.renameFolderData })
        }

        // (修改)當前資料夾
        this.$store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)
        this.isBusy = false
        this.closeEditModal()
        this.useAlertToast(true, '更新成功')
      })
    },

    // ------------------------------------------------------------------------------------------
    // 刪除
    // ------------------------------------------------------------------------------------------
    // (觸發)刪除檔案
    submitDeleteFolder() {
      if (this.adminFolderState.selectedMutiFolders.length > 0) {
        this.submitSelectedFolder(this.rightClick)
        return
      }
      this.$bvModal.show('folderDeleteModal')
    },

    // (觸發)關閉備註
    closeDeleteModal() {
      this.rightClick = {}
      this.$bvModal.hide('folderDeleteModal')
    },

    // (刪除)檔案
    confirmDeleteModal() {
      this.isBusy = true

      this.setFolderDelete(this.rightClick.type, [this.rightClick.id], () => {
        // (修改)側欄結構
        if (this.rightClick.type === 'folder') {
          this.refreshSidebarList('delete', { id: this.rightClick.id })
        }

        // (修改)當前資料夾
        let resolveListData = JSON.parse(JSON.stringify(this.adminFolderState.currentFolder))
        resolveListData = resolveListData.filter(item => !(item.id === this.rightClick.id && item.type === this.rightClick.type))
        this.$store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)
        this.isBusy = false
        this.closeDeleteModal()
      })
    },

    // (重設)組件
    resetComponentData() {
      this.adminFolderState.renameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
    },

    // (觸發)重新整理
    submitRefreshFolder() {
      if (this.adminFolderState.isBusyLoading) return
      if (!this.currentLocalFolder) {
        // this.getFolderListInitData()
        this.getOpenFolderChildren(null, () => {
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
        })
      } else {
        this.getOpenFolderChildren(this.currentLocalFolder, () => {
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
        })
      }
    },
  },
  setup() {
    const {
      isBusy,
      blankEditData,
      useAlertToast,
      getOpenFolderChildren,
      getFolderListInitData,
      refreshSidebarList,
      setFolderUpdate,
      setFolderDelete,
      getFileDownload,
    } = useFolderList()

    const {
      folderType,
    } = useFolderSetting()

    return {
      folderType,
      isBusy,
      blankEditData,
      useAlertToast,
      getFolderListInitData,
      refreshSidebarList,
      getOpenFolderChildren,
      setFolderUpdate,
      setFolderDelete,
      getFileDownload,
    }
    // getTodoListInitData()
  },
}
</script>

<style lang="scss">
.custom-bg-color {
  background-color: #cae4f474 !important;
}
</style>

<style lang="scss" scoped>
.dropdown-item-area {
  transition: transform ease-out 200ms;
  font-size: 14px;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}

.list-area {
  .component-folder-image {
    img {
      max-height: 30px;
      width: 100%;
      max-width: 30px;
      // user-select: none;
    }
  }
}

</style>
