import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    currentFolder: [], // 當前資料夾
    historyFolderPath: [], // 歷史路徑
    currentLocalPath: [], // 當前資料夾路徑
    structureFolder: [], // 資料夾結構
    activeFolderContactId: null,
    // selectedMode: false,
    focusFolderData: null, // 點擊到文件
    renameFolderData: {
      id: null,
      type: null,
      newName: null,
    },
    selectedMutiFolders: [], // 多選檔案
    copySelectedFolders: [], // 複製檔案
    searchQuery: null, // 搜尋
    displayType: 'gallery', // gallery, list
    // uploadProgress: 87,
  },
  getters: {},
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_FOCUS_FOLDER_STATE(state, val) {
      state.focusFolderData = val
    },
    UPDATE_RENAME_FOLDER_STATE(state, val) {
      state.renameFolderData = val
    },
    UPDATE_CURRENT_FOLDER_LIST(state, val) {
      state.currentFolder = val
    },
    UPDATE_STRUCTURE_FOLDER_LIST(state, val) {
      state.structureFolder = val
    },
    UPDATE_ACTIVE_FOLDER_ID(state, val) {
      state.activeFolderContactId = val
    },
    UPDATE_DISPLAY_TYPE_STATE(state, val) {
      state.displayType = val
    },
    // (多選)
    SELECTED_MUTI_FOLDERS(state, val) {
      const index = state.selectedMutiFolders.findIndex(item => item.id === val.id && item.type === val.type)

      if (index !== -1) {
        state.selectedMutiFolders.splice(index, 1)
      } else {
        state.selectedMutiFolders.push(val)
      }
    },
    // (指定)多選
    SELECTED_MUTI_FOLDERS_ARRAY(state, items) {
      state.selectedMutiFolders = items
    },
    // (取消)多選
    SELECTED_MUTI_FOLDERS_CANCEL(state) {
      state.selectedMutiFolders = []
    },
    // (開啟)側欄資料夾
    OPEN_SIDEBAR_LOCAL_FOLDER(state, arr) {
      state.currentLocalPath = []
      state.historyFolderPath = []
      state.selectedMutiFolders = []
      state.focusFolderData = null
      state.renameFolderData = {
        id: null,
        type: null,
        newName: null,
      }

      state.currentLocalPath = arr.map(path => {
        const resolve = {
          id: path.id,
          name: path.name,
          parent_folder_id: path.parent_folder_id,
        }
        return resolve
      })
    },
    // (開啟)資料夾
    OPEN_LOCAL_FOLDER(state, val) {
      state.currentLocalPath.push(val)
      state.activeFolderContactId = val.id
      state.selectedMutiFolders = []
      state.focusFolderData = null
      state.renameFolderData = {
        id: null,
        type: null,
        newName: null,
      }
      if (state.historyFolderPath.length > 0) {
        const lastHistoryFolderPath = state.historyFolderPath[state.historyFolderPath.length - 1]
        if (val.parent_folder_id === lastHistoryFolderPath.parent_folder_id) {
          // state.historyFolderPath.pop()
          state.historyFolderPath = []
        }
      }
    },
    // (回上層)
    PREVIOUS_LOCAL_FOLDER(state) {
      state.selectedMutiFolders = []
      state.focusFolderData = null
      state.renameFolderData = {
        id: null,
        type: null,
        newName: null,
      }
      if (state.currentLocalPath.length > 0) {
        const lastElement = state.currentLocalPath.pop()
        if (state.historyFolderPath.length > 0) {
          const lastHistoryItem = state.historyFolderPath[state.historyFolderPath.length - 1]
          if (lastHistoryItem.parent_folder_id === lastElement.id) {
            state.historyFolderPath.push(lastElement)
          } else {
            state.historyFolderPath = []
            state.historyFolderPath.push(lastElement)
          }
        } else {
          state.historyFolderPath.push(lastElement)
        }
      }
      if (state.currentLocalPath.length === 0) state.activeFolderContactId = null
    },
    // (回下層)
    NEXT_LOCAL_FOLDER(state) {
      state.selectedMutiFolders = []
      state.focusFolderData = null
      state.renameFolderData = {
        id: null,
        type: null,
        newName: null,
      }
      if (state.historyFolderPath.length > 0) {
        const lastHistoryItem = state.historyFolderPath.pop()
        state.currentLocalPath.push(lastHistoryItem)
        state.activeFolderContactId = lastHistoryItem.id
      }
    },
    // (回根結點)
    ROOT_LOCAL_FOLDER(state) {
      state.historyFolderPath = []
      state.currentLocalPath = []
      state.activeFolderContactId = null
      state.renameFolderData = {
        id: null,
        type: null,
        newName: null,
      }
      // if (state.historyFolderPath.length > 0) {
      //   const lastElement = state.historyFolderPath.pop() // currentLocalPath
      //   state.currentLocalPath.push(lastElement)
      // }
    },
  },
  actions: {
    // (查詢)雲端空間
    getFolderList(ctx, resolveData) {
      if (!resolveData.folder_id) {
        return new Promise((resolve, reject) => {
          axios
            .get('/admin/file-system/folders', { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/file-system/folders/${resolveData.folder_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)資料夾 (未用)
    setFolderCreate(ctx, folderData) {
      return new Promise((resolve, reject) => {
        axios('/admin/file-system/folders', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...folderData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)檔案
    setFileCreate(ctx, fileData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/file-system/folders/${fileData.folder_id}/file`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...fileData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)檔案
    setFileUpload(ctx, fileData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/file-system/folders/${fileData.folder_id}/file/${fileData.log_id}`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: fileData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (拼接)檔案
    getFileStatus(ctx, fileData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/file-system/folders/${fileData.folder_id}/file/${fileData.file_id}/status`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)資料夾
    setFolderUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/file-system/folders/${resolveData.folder_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)檔案
    setFileUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/file-system/folders/${resolveData.folder_id}/file/${resolveData.file_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (移除)資料夾/檔案 file_id:[int], folder_id:[int]
    setFolderDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/file-system/delete', {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (下載)檔案
    getFileDownload(ctx, fileData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/file-system/folders/${fileData.folder_id}/file/${fileData.file_id}/download`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // // (移除)資料夾
    // setFolderDelete(ctx, resolveData) {
    //   if (!resolveData.folder_id) {
    //     return new Promise((resolve, reject) => {
    //       axios('/admin/file-system/folders', {
    //         method: 'DELETE',
    //         headers: {
    //           'Access-Token': useJwt.getToken(),
    //         },
    //         data: {
    //           ...resolveData.data,
    //         },
    //       })
    //         .then(response => resolve(response))
    //         .catch(error => reject(error))
    //     })
    //   }
    //   return new Promise((resolve, reject) => {
    //     axios(`/admin/file-system/folders/${resolveData.folder_id}`, {
    //       method: 'DELETE',
    //       headers: {
    //         'Access-Token': useJwt.getToken(),
    //       },
    //       data: {
    //         ...resolveData.data,
    //       },
    //     })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

    // // (移除)檔案
    // setFileDelete(ctx, resolveData) {
    //   return new Promise((resolve, reject) => {
    //     axios(`/admin/file-system/folders/${resolveData.folder_id}/file/${resolveData.file_id}`, {
    //       method: 'DELETE',
    //       headers: {
    //         'Access-Token': useJwt.getToken(),
    //       },
    //       data: {
    //         ...resolveData.data,
    //       },
    //     })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
