<template>
  <div class="folder-application-footer">
    <div class="footer-content">
      <div>
        <span>{{ currentFolderFilter.length }} 個項目</span>
        <span
          v-if="adminFolderState.selectedMutiFolders.length > 0 || adminFolderState.focusFolderData !== null"
          class="ml-2"
        >
          已選取 {{ adminFolderState.selectedMutiFolders.length > 0 ? adminFolderState.selectedMutiFolders.length : 1 }} 個項目
        </span>
      </div>

      <div class="d-flex">
        <!-- <feather-icon
          icon="AlignJustifyIcon"
          size="20"
          class="mr-1"
        />
        <feather-icon
          icon="AirplayIcon"
          size="20"
        /> -->

        <!-- v-b-tooltip.hover.v-secondary
        :title="!adminFolderState.activeFolderContactId ? null : '回根節點'"
        :class=" { 'path-icon-disable': !adminFolderState.activeFolderContactId || adminFolderState.isBusyLoading }"
        @click="submitRootFolder" -->

        <div
          class="display-icon-btn-sm"
          :class="{'display-active': adminFolderState.displayType === 'list'}"
          @click="$store.commit('admin-folder/UPDATE_DISPLAY_TYPE_STATE', 'list')"
        >
          <b-img
            src="/admin/images/file/action/list.svg"
            class="display-icon-btn-image"
            :draggable="false"
          />
        </div>

        <div
          class="display-icon-btn-sm"
          :class="{'display-active': adminFolderState.displayType === 'gallery'}"
          @click="$store.commit('admin-folder/UPDATE_DISPLAY_TYPE_STATE', 'gallery')"
        >
          <b-img
            src="/admin/images/file/action/gallery.svg"
            class="display-icon-btn-image"
            :draggable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    isBusyLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    adminFolderState() {
      return this.$store.state['admin-folder']
    },
    currentFolderFilter() {
      const adminFolderState = this.$store.state['admin-folder']
      const searchKeyword = adminFolderState.searchQuery ? adminFolderState.searchQuery.toLowerCase() : adminFolderState.searchQuery
      if (searchKeyword) return adminFolderState.currentFolder.filter(f => !f.name || f.name.includes(searchKeyword.toLowerCase()))
      return adminFolderState.currentFolder
    },
  },
  setup() {
  },
}
</script>

<style lang="scss" scoped>
.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;

  .display-icon-btn-sm {
    transition: transform ease-out 200ms;
    padding: 5px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-left: 3px;
    line-height: 20px;
    .display-icon-btn-image {
      width: 16px;
      height: 16px;
    }
    &:hover {
      cursor: pointer;
      background-color: #9de0f64f;
      .display-icon-btn-image {
        transform: scale(1.05);
        animation: btn-breathe .8s linear infinite;
      }
    }
  }

  .display-active {
    outline: 1px solid #4796b1;
  }
}
</style>
