<template>
  <div class="folder-application-content">
    <div
      v-if="!adminFolderState.isBusyLoading"
      class="animate__animated animate__fadeIn"
    >
      <!-- <FolderGallery /> -->

      <div ref="uploadCardContent">
        <div
          ref="uploadCard"
          no-body
          class="image-explorer-contain upload-card"
          @dragenter="dragEnter"
          @dragover="preventDefault"
          @dragleave="dragLeave"
          @drop="dropFile"
        >
          <div
            ref="dropArea"
            class="dropArea hidden"
          >
            <div class="upload-label">
              <span class="label-title">
                把檔案拖放到這裡
              </span>
              <b-img
                :src="require('@/assets/images/icons/image-processing.png')"
                fluid
              />
            </div>

          <!-- <input
            type="file"
            multiple
            hidden
            @input="resolveFile($event.target.files)"
          > -->
          </div>

          <div
            class="folder-area"
            draggable="false"
            @click="setFocusFolderDataClear"
          >
            <FolderList
              v-if="adminFolderState.displayType === 'list'"
              @submit-open-application-info="() => {
                $emit('open-application-info')
              }"
            />

            <FolderGallery
              v-else
              @submit-open-application-info="() => {
                $emit('open-application-info')
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="content-empty p-2"
    >
      <b-img
        src="/admin/images/common/loading-2.png"
        rounded
        height="50"
        width="50"
      />
    </div>

    <UploadModal
      ref="uploadExplorerModal"
      :is-hiden-refresh="true"
      @reset-data="submitRefreshFolder"
    />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { useFolderUpload, useFolderList } from '../useFolder'
import FolderGallery from '../comnponent/FolderGallery.vue'
import FolderList from '../comnponent/FolderList.vue'
import UploadModal from '../comnponent/UploadModal.vue'

export default {
  components: {
    BImg,
    FolderList,
    FolderGallery,
    UploadModal,
  },
  props: {
    isBusyLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // editCol: '',
      // editColData: '',
      // showDescription: false,
      temporaryFile: [],
      // fileList: [],
      // siteInput: null,
      // siteType: 'image',
      // siteIndex: null,
      // siteLock: null,
      // siteSize: 15728640,
      // inputMessage: '',
      // previewFile: [],
      // rightClick: {},
    }
  },
  computed: {
    adminFolderState() {
      return this.$store.state['admin-folder']
    },
  },
  methods: {
    setFocusFolderDataClear() {
      this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', null)

      const nameData = {
        id: null,
        type: null,
        newName: null,
      }
      this.$store.commit('admin-folder/UPDATE_RENAME_FOLDER_STATE', nameData)
    },
    // ------------------------------------------------------------------------------------
    preventDefault(e) {
      e.stopPropagation()
      e.preventDefault()
    },

    dragEnter(e) {
      this.preventDefault(e)
      this.elementNode = e.target
      const { types } = e.dataTransfer
      if (types.includes('Files')) {
        this.$refs.dropArea.classList.remove('hidden')
      }
    },

    dragLeave(e) {
      this.preventDefault(e)
      if (this.elementNode === e.target) this.$refs.dropArea.classList.add('hidden')
    },

    dropFile(e) {
      this.preventDefault(e)
      if (this.isBusy) {
        this.$refs.dropArea.classList.add('hidden')
        this.useAlertToast(false, '正再上傳文件中，請稍候...')
        return
      }
      const { items } = e.dataTransfer // files,

      this.temporaryFile = []

      const onComplete = () => {
        setTimeout(() => {
          this.$refs.uploadExplorerModal.uploadWaiting(this.temporaryFile)
        }, 200)
      }

      const processFilesAndFolders = async () => {
        const promises = []
        for (let i = 0; i < items.length; i += 1) {
          const item = items[i]
          const entry = item.webkitGetAsEntry()

          if (entry) {
            const delay = i * 5
            promises.push(new Promise(resolve => {
              setTimeout(async () => {
                if (entry.isFile) {
                  await this.processFile(entry, delay)
                } else if (entry.isDirectory) {
                  await this.processDirectory(entry)
                }
                resolve()
              }, delay)
            }))
          }
        }
        // 處理完畢後觸發 onComplete
        await Promise.all(promises)
        onComplete()
      }

      processFilesAndFolders()
      this.$refs.dropArea.classList.add('hidden')
    },

    // 處理文件的方法
    async processFile(entry, delay) {
      return new Promise(resolve => {
        entry.file(file => {
          setTimeout(() => {
            const timeId = Date.now()
            this.formatBlankFileData(timeId, file, { name: file.name, fullPath: `/${file.name}` }, this.adminFolderState.activeFolderContactId)
            resolve()
          }, delay)
        })
      })
    },

    // 處理文件夾的方法
    async processDirectory(entry) {
      return new Promise(resolve => {
        this.readDirectory(entry, this.adminFolderState.activeFolderContactId, resolve)
      })
    },

    // (遞迴)資料結構
    readDirectory(directory, node, callback) {
      const timeNode = Date.now()
      this.formatBlankDirectoryData(timeNode, directory, node)
      const reader = directory.createReader()
      reader.readEntries(entries => {
        const processEntries = index => {
          if (index >= entries.length) {
            callback()
            return
          }

          const entry = entries[index]
          if (entry.isFile) {
            entry.file(file => {
              const timeId = Date.now()
              this.formatBlankFileData(timeId, file, entry, timeNode)
              setTimeout(() => processEntries(index + 1), 20)
            })
          } else if (entry.isDirectory) {
            setTimeout(() => {
              this.readDirectory(entry, timeNode, () => {
                setTimeout(() => processEntries(index + 1), 20)
              })
            }, 20)
          } else {
            setTimeout(() => processEntries(index + 1), 20)
          }
        }

        processEntries(0)
      })
    },

    // (格式化)檔案
    formatBlankFileData(rId, file, item, node) {
      const fileData = {
        rId,
        name: item.name,
        path: item.fullPath,
        type: file.type,
        size: file.size,
        parent_folder_id: node,
        file,
      }

      const resolveData = this.syncObject(this.blankFileData, fileData)
      this.temporaryFile.push(resolveData)
    },

    // (格式化)資料夾
    formatBlankDirectoryData(rId, directory, node) {
      const directoryData = {
        rId,
        name: directory.name,
        path: directory.fullPath,
        parent_folder_id: node,
        isDirectory: true,
      }

      const resolveData = this.syncObject(this.blankFileData, directoryData)
      this.temporaryFile.push(resolveData)
    },
    // ------------------------------------------------------------------------------------
    // (觸發)重新整理
    submitRefreshFolder() {
      if (this.adminFolderState.isBusyLoading) return
      if (!this.adminFolderState.activeFolderContactId) {
        // this.getFolderListInitData()
        this.getOpenFolderChildren(null, () => {
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
          this.refreshSidebarList('refresh')
        })
      } else {
        this.getOpenFolderChildren({ id: this.adminFolderState.activeFolderContactId }, () => {
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
          // this.refreshSidebarList('refresh')
        })
      }
    },
  },
  setup() {
    const {
      blankFileData,
      syncObject,
    } = useFolderUpload()

    const {
      getOpenFolderChildren,
      getFolderListInitData,
      refreshSidebarList,
    } = useFolderList()

    return {
      blankFileData,
      syncObject,

      getOpenFolderChildren,
      getFolderListInitData,
      refreshSidebarList,
    }
  },
}
</script>

<style lang="scss" scoped>
// .upload-card {
//   position: relative;
//   min-height: 68vh;
//   // background-color: red;
//   .dropArea {
//     min-height: 50%;
//     width: 100%;
//     position: absolute;
//     z-index: 1000;
//     padding: 0 30px;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     background-color: #282828;
//     min-height: 70vh;
//     opacity: .9;
//     text-align: center;
//     align-self: center;
//     color: #fff;
//     &.hidden {
//       display: none;
//     }
//   }
//   .upload-label {
//     position: sticky;
//     top: 50%;
//     transform: translateY(-50%);
//     width: 100%;
//     text-align: center;
//     border-radius: 10px;
//     height: 40vh;
//     border: #898989 dashed 3px;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     .label-title {
//       display: block;
//       font-size: 2rem;
//       font-weight: 900;
//       padding: 1rem 0 0.5rem;
//     }
//     .label-subtitle {
//       font-size: 1rem;
//     }
//   }

//   .upload-label-text {
//     width: 100%;
//     text-align: center;
//     font-size: 1rem;
//     font-weight: 900;
//     padding: 1rem 0 0.5rem;
//   }
// }

.upload-card {
  position: relative;
  min-height: 68vh;
  .dropArea {
    min-height: 50%;
    position: sticky;
    z-index: 1000;
    padding: 30px;
    top: -30px;
    bottom: -30px;
    right: -30px;
    left: -30px;
    background-color: #282828;
    min-height: 90vh;
    opacity: .9;
    text-align: center;
    align-self: center;
    color: #fff;
    &.hidden {
      display: none;
    }
  }
  .upload-label {
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    border-radius: 10px;
    height: 40vh;
    border: #898989 dashed 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .label-title {
      display: block;
      font-size: 2rem;
      font-weight: 900;
      padding: 1rem 0 0.5rem;
    }
    .label-subtitle {
      font-size: 1rem;
    }
  }

  .upload-label-text {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 900;
    padding: 1rem 0 0.5rem;
  }
}

.folder-area {
  user-select: none;
  min-height: 68vh;
}
</style>
