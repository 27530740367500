<template>
  <div class="component-folder-image">
    <b-img
      v-show="!isActive"
      :draggable="false"
      :src="closeFolderImage"
      :style="{ height: size + 'px', weight: size + 'px' }"
      alt="資料夾"
    />

    <b-img
      v-show="isActive"
      :draggable="false"
      :src="openFolderImage"
      :style="{ height: size + 'px', weight: size + 'px' }"
      alt="資料夾"
    />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 20,
    },
    closeFolderImage: {
      type: String,
      default: '/admin/images/file/folder/close-folder.svg',
    },
    openFolderImage: {
      type: String,
      default: '/admin/images/file/folder/open-folder.svg',
    },
  },
  setup() {
  },
}
</script>

<style lang="scss" scoped>

</style>
