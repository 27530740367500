<template>
  <div
    class="structure-area"
  >
    <!-- structure-{{ structureId }} -->
    <div
      :id="`structure-${structureId}`"
      class="structure-tree tree-item"
    >
      <div
        class="folder"
        :class=" {'folder-active': structureId === adminFolderState.activeFolderContactId}"
        :style="{ paddingLeft: layers * 15 + 'px' }"
      >
        <div
          class="folder-arrow"
          @click="openChildren"
        >
          <div v-if="structure.children.length > 0">
            <feather-icon
              v-if="!isStructureOpen"
              icon="ChevronRightIcon"
            />

            <feather-icon
              v-else
              icon="ChevronDownIcon"
              class="text-muted"
            />
          </div>

          <div v-else>
            <feather-icon
              icon="ChevronRightIcon"
              class="text-muted folder-arrow-empty"
            />
          </div>
        </div>

        <div
          class="folder-image"
          @click="openFile"
        >
          <FolderImage
            :is-active="structureId === adminFolderState.activeFolderContactId"
            :size="isRoot ? 18 : 16"
            open-folder-image="/admin/images/file/folder/folder.png"
            :close-folder-image="isRoot ? '/admin/images/file/folder/root-folder.svg' : '/admin/images/file/folder/close-folder.svg'"
          />
        </div>

        <div
          class="folder-title"
          @click="openFile"
        >
          {{ structure.name }}
          <!-- / {{ structureId }} -->
          <!-- / {{ isStructureOpen }} -->
          <!-- {{ structureId }} / {{ adminFolderState.activeFolderContactId }} / {{ isIdInChildren(structure.children, structureId) }} -->
          <!-- sdfjkashdfkjashdfjkasdfjkasgfjhsgdfkashdgfaskjdf -->
        </div>
      </div>
    </div>

    <!-- v-if="structureId === adminFolderState.activeFolderContactId" -->
    <div v-if="isStructureOpen">
      <folder-structure
        v-for="folder in structure.children"
        :key="folder.id"
        :structure-id="folder.id"
        :structure="folder"
        :layers="layers + 1"
        :is-root="false"
      />
    </div>
  </div>
</template>

<script>
import { useFolderList } from '../useFolder'

export default {
  name: 'FolderStructure',
  components: {
    FolderImage: () => import('./FolderImage.vue'),
  },
  props: {
    structureId: {
      required: true,
      type: Number,
    },
    structure: {
      required: true,
      type: Object,
    },
    layers: {
      type: Number,
      required: true,
    },
    isRoot: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isStructureOpen: false,
    }
  },
  computed: {
    adminFolderState() {
      return this.$store.state['admin-folder']
    },
  },
  mounted() {
    this.initStructure()
  },
  methods: {
    isIdInChildren(data, id) {
      for (let i = 0; i < data.length; i += 1) {
        const item = data[i]

        if (item.id === this.adminFolderState.activeFolderContactId) {
          return true
        }

        if (item.children && item.children.length > 0) {
          const foundInChildren = this.isIdInChildren(item.children, id)
          if (foundInChildren) {
            return true
          }
        }
      }
      // if (id === this.adminFolderState.activeFolderContactId) return true
      return false
    },

    // (打開)子組件
    openChildren() {
      this.isStructureOpen = !this.isStructureOpen
    },

    // (開啟)檔案
    openFile() {
      if (this.structureId === this.adminFolderState.activeFolderContactId) return
      this.isStructureOpen = false
      this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', true)
      this.getOpenFolderChildren({ id: this.structureId }, () => {
        this.$store.commit('admin-folder/UPDATE_ACTIVE_FOLDER_ID', this.structureId)
        this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
        if (this.isStructureOpen) this.initStructure()

        // 更新:當前資料夾路徑
        const structurePath = this.updateCurrentLocalPath(this.adminFolderState.structureFolder, this.structureId)
        this.$store.commit('admin-folder/OPEN_SIDEBAR_LOCAL_FOLDER', structurePath)
      })
    },

    // (初始化)
    initStructure() {
      this.getSidebarChildren(this.structureId)
    },
  },
  setup() {
    const {
      getSidebarChildren,
      getOpenFolderChildren,

      updateCurrentLocalPath,
    } = useFolderList()

    return {
      getSidebarChildren,
      getOpenFolderChildren,

      updateCurrentLocalPath,
    }
  },
}
</script>

<style lang="scss" scoped>
$primary: #65d8ff;

.structure-area {
  .structure-tree {
    .folder {
      // padding: 8px 0;
      display: flex;
      align-items: center;
      max-width: 300px;
      padding-right: 15px;
      .folder-arrow {
        margin-right: 10px;
        // background-color: red;
        // width: 50px;
        &:hover {
          cursor: pointer;
        }

        .folder-arrow-empty {
          opacity: 0;
        }
      }

      .folder-image {
        display: inline-block;
        margin-right: 5px;
        &:hover {
          cursor: pointer;
        }
      }

      .folder-title {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 40px;
        line-height: 40px;
        width: 100%;
        &:hover {
          cursor: pointer;
          color: $primary;
        }
      }

      &:hover {
        // width: 100%;
        background-color: #adadad30;
      }

      // &.folder--open {
      //     color: $primary;
      //     &::before {
      //       content: "\f07c";
      //     }
      // }
    }

    .folder-active {
      color: #65d8ff;
      // background-color: red;
    }
  }
}
</style>
