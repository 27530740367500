<template>
  <div class="folder-application">
    <div
      class="body-content-overlay"
      :class="{'show': shallOpenApplicationSidebarState || shallOpenApplicationInfoState }"
      @click="shallOpenApplicationSidebarState=shallOpenApplicationInfoState=false"
    />

    <div>
      <FolderContent
        :is-busy-loading="isBusyLoading"
        @open-application-info="openApplicationInfo"
      />
    </div>

    <portal to="content-renderer-sidebar-left">
      <FolderSidebar
        :shall-open-application-sidebar-state.sync="shallOpenApplicationSidebarState"
        :is-busy-loading="isBusyLoading"
      />
    </portal>

    <portal to="content-renderer-sidebar-right">
      <FolderInfo
        :shall-open-application-info-state.sync="shallOpenApplicationInfoState"
        :is-busy-loading="isBusyLoading"
      />
    </portal>

    <portal to="content-renderer-sidebar-header">
      <FolderHeader
        :shall-open-application-sidebar-state.sync="shallOpenApplicationSidebarState"
        :is-busy-loading="isBusyLoading"
        @open-application-sidebar="openApplicationSidebar"
        @open-application-info="openApplicationInfo"
      />
    </portal>

    <portal to="content-renderer-sidebar-footer">
      <FolderFooter
        :is-busy-loading="isBusyLoading"
      />
    </portal>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import FolderHeader from './FolderListHeader.vue'
import FolderSidebar from './FolderListSidebar.vue'
import FolderInfo from './FolderListInfo.vue'
import FolderContent from './FolderListContent.vue'
import FolderFooter from './FolderListFooter.vue'
import useStoreModule from '../useStoreModule'
import { useFolderList } from '../useFolder'
import store from '@/store'

export default {
  components: {
    FolderHeader,
    FolderSidebar,
    FolderInfo,
    FolderContent,
    FolderFooter,
  },
  setup() {
    // 註冊模組
    const FOLDER_ADMIN_STORE_MODULE_NAME = 'admin-folder'

    if (!store.hasModule(FOLDER_ADMIN_STORE_MODULE_NAME)) store.registerModule(FOLDER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(FOLDER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(FOLDER_ADMIN_STORE_MODULE_NAME)
    })

    const shallOpenApplicationSidebarState = ref(false)
    const shallOpenApplicationInfoState = ref(false)

    const openApplicationSidebar = () => {
      shallOpenApplicationInfoState.value = false
      shallOpenApplicationSidebarState.value = true
    }

    const openApplicationInfo = () => {
      shallOpenApplicationSidebarState.value = false
      if (store.state['admin-folder'].focusFolderData) shallOpenApplicationInfoState.value = true
    }

    const {
      isBusyLoading,
      getFolderListInitData,
    } = useFolderList()

    getFolderListInitData()

    return {
      isBusyLoading,
      shallOpenApplicationSidebarState,
      shallOpenApplicationInfoState,

      openApplicationSidebar,
      openApplicationInfo,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/my/app-folder.scss";
</style>
