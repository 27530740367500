<template>
  <b-modal
    :id="uploadExplorerId"
    no-close-on-backdrop
    no-close-on-esc
    centered
    header-bg-variant="primary"
    size="lg"
    body-class="p-0"
    @hidden="handleHide"
    @close="handleHide"
  >
    <!-- visible -->
    <template #modal-title>
      <h4
        v-if="adminFolderState.currentLocalPath.length === 0"
        class="m-0 model-header"
      >
        雲端上傳
      </h4>

      <h6
        v-else
        class="m-0 model-header"
      >
        <div>
          上傳文件到【
          <span
            v-for="path in adminFolderState.currentLocalPath"
            :key="path.id"
          >/ {{ path.name }} </span>
          】
        </div>
      </h6>
      {{ adminFolderState.activeFolderContactId }}
    </template>

    <div class="upload-card-content">
      <div
        ref="uploadCard"
        no-body
        class="image-explorer-contain upload-card"
        @dragenter="dragEnter"
        @dragover="preventDefault"
        @dragleave="dragLeave"
        @drop="dropFile"
      >
        <div
          ref="dropArea"
          class="dropArea hidden"
        >
          <div class="upload-label">
            <span class="label-title">
              把檔案拖放到這裡
            </span>
            <b-img
              :src="require('@/assets/images/icons/image-processing.png')"
              fluid
            />
          </div>
        </div>

        <div>
          <!-- class="d-block d-sm-none" -->
          <label class="upload-label-text">
            <span>
              把檔案拖放到這裡或者 <strong class="cursor-pointer text-primary">點擊這裡</strong> 選擇檔案
            </span>
            <input
              type="file"
              multiple
              hidden
              @input="resolveFile($event.target.files)"
            >
          </label>

        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="uploadFileList"
          :fields="tableColumns"
          responsive
          striped
          hover
          show-empty
          class="position-relative"
          :stacked="$store.getters['app/currentBreakPoint'] !== 'lg' && $store.getters['app/currentBreakPoint'] !== 'xl'"
        >
          <!-- @filtered="onFiltered" -->

          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="perPage"
              :columns="tableColumnsFilter.length"
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(file_name)="data">
            <div
              v-b-tooltip.hover.focus.v-secondary
              :title="data.item.path"
              class="text-nowrap title-text"
            >
              {{ data.item.path }}
            </div>
            <!-- <div>{{ data.item.rId }} / {{ data.item.id }}</div> -->
          </template>

          <!-- 欄位: 類型 -->
          <!-- <template #cell(type)="data">
            <div class="text-nowrap">
              {{ data.item.isDirectory ? '資料夾' : '檔案' }}

            </div>
            <b-badge
              :variant="ui.directoryList[1][data.item.isDirectory]"
            >
              {{ ui.directoryList[0][data.item.isDirectory] }}
            </b-badge>
          </template> -->

          <!-- 欄位: 大小 -->
          <template #cell(size)="data">
            <div class="text-nowrap">
              <span v-if="data.item.isDirectory">
                <b-badge
                  :variant="ui.directoryList[1][data.item.isDirectory]"
                >
                  {{ ui.directoryList[0][data.item.isDirectory] }}
                </b-badge>
              </span>

              <span v-else>
                {{ convertBytes(data.item.size) }}
              </span>
              <!-- {{ data.item.isDirectory ? '資料夾' :convertBytes(data.item.size) }} -->
            </div>
          </template>

          <!-- 欄位: 上傳狀態 -->
          <template #cell(state)="data">
            <div
              v-if="data.item.isBusy"
              style="min-width: 100px"
            >
              <div
                v-if="!data.item.isUpload"
                class="text-primary"
              >
                等待上傳
              </div>

              <div
                v-else
                class="text-primary"
              >
                <!-- progress-bar -->
                <b-progress
                  striped
                  max="100"
                  class="w-100"
                >
                  <b-progress-bar
                    variant="success"
                    :value="data.item.bar"
                    animated
                    show-progress
                  />
                </b-progress>
              </div>
            </div>

            <div
              v-else
              style="min-width: 100px"
            >
              <div
                v-if="!data.item.isUpload"
                class="text-success"
              >
                上傳成功
              </div>

              <div
                v-else
                class="text-danger title-text"
              >
                {{ data.item.error }}
              </div>
            </div>

            <!-- <div>
              {{ data.item }}
            </div> -->
          </template>
        </b-table>

        <!-- 拖曳進來的資料夾：{{ uploadFileList }} -->
      </div>
    </div>

    <template #modal-footer>
      <div class="text-center">

        <b-button
          variant="outline-secondary"
          size="sm"
          class="mr-1"
          :disabled="isBusy"
          @click="handleCancel"
        >
          取消上傳
        </b-button>

        <b-button
          variant="primary"
          size="sm"
          :disabled="isBusy"
          @click="submitUpload"
        >
          開始上傳
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import {
  BModal, BTable, BSkeletonTable, BImg, VBTooltip, BButton, BProgress, BProgressBar, BBadge,
} from 'bootstrap-vue'

import { useAlert } from '@/libs/mixins/index' // useSwalToast
import { useFolderUpload, useFolderSetting } from '../useFolder'

export default {
  components: {
    BImg,
    BBadge,
    BButton,
    BModal,
    BTable,
    BSkeletonTable,
    BProgress,
    BProgressBar,
    // BButton,
    // BTable,
    // BSkeletonTable,
    // BInputGroup,
    // BInputGroupPrepend,
    // BFormInput,
    // BRow,
    // BCol,
    // BProgress,
    // BProgressBar,
  },
  directives: {
    // Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isHidenRefresh: {
      type: Boolean,
      default: false,
    },
    uploadExplorerId: {
      type: String,
      default: 'upload-explorer-modal',
    },
    // originalFile: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      temporaryFile: [],
    }
  },
  computed: {
    adminFolderState() {
      return this.$store.state['admin-folder']
    },
  },
  methods: {
    // KB轉換
    convertBytes(bytes) {
      const kilobytes = (bytes / 1024).toFixed(1)
      const gigabytes = (kilobytes / (1024 * 1024)).toFixed(1)
      const megabytes = (kilobytes / 1024).toFixed(1)

      if (kilobytes >= 1024 * 1024) return `${gigabytes.toLocaleString()} GB`
      if (kilobytes >= 1024) return `${megabytes.toLocaleString()} MB`
      return `${kilobytes.toLocaleString()} KB`
    },

    // ------------------------------------------------------------------------------------
    // (觸發)上傳
    submitUpload() {
      if (this.isBusy) {
        this.useAlertToast(false, '正再上傳文件中，請稍候...')
        return
      }
      this.isBusy = true
      this.uploadFileQueue()
    },
    // ------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------------------
    preventDefault(e) {
      e.stopPropagation()
      e.preventDefault()
    },

    dragEnter(e) {
      this.preventDefault(e)
      this.elementNode = e.target
      const { types } = e.dataTransfer
      if (types.includes('Files')) {
        this.$refs.dropArea.classList.remove('hidden')
      }
    },

    dragLeave(e) {
      this.preventDefault(e)
      if (this.elementNode === e.target) this.$refs.dropArea.classList.add('hidden')
    },

    dropFile(e) {
      this.preventDefault(e)
      if (this.isBusy) {
        this.$refs.dropArea.classList.add('hidden')
        this.useAlertToast(false, '正再上傳文件中，請稍候...')
        return
      }
      const { items } = e.dataTransfer // files,

      this.temporaryFile = []

      const onComplete = () => {
        setTimeout(() => {
          this.uploadWaiting(this.temporaryFile)
        }, 200)
      }

      const processFilesAndFolders = async () => {
        const promises = []
        for (let i = 0; i < items.length; i += 1) {
          const item = items[i]
          const entry = item.webkitGetAsEntry()

          if (entry) {
            const delay = i * 5
            promises.push(new Promise(resolve => {
              setTimeout(async () => {
                if (entry.isFile) {
                  await this.processFile(entry, delay)
                } else if (entry.isDirectory) {
                  await this.processDirectory(entry)
                }
                resolve()
              }, delay)
            }))
          }
        }
        // 處理完畢後觸發 onComplete
        await Promise.all(promises)
        onComplete()
      }

      processFilesAndFolders()
      this.$refs.dropArea.classList.add('hidden')
    },

    // 處理文件的方法
    async processFile(entry, delay) {
      return new Promise(resolve => {
        entry.file(file => {
          setTimeout(() => {
            const timeId = Date.now()
            this.formatBlankFileData(timeId, file, { name: file.name, fullPath: `/${file.name}` }, this.activeFolderContactId)
            resolve()
          }, delay)
        })
      })
    },

    // 處理文件夾的方法
    async processDirectory(entry) {
      return new Promise(resolve => {
        this.readDirectory(entry, this.activeFolderContactId, resolve)
      })
    },

    readFile(files) { // , callback
      // const onComplete = () => {
      //   callback()
      // }
      const filteredFiles = Array.from(files).filter(file => file.type !== '')

      filteredFiles.forEach((file, delay) => {
        setTimeout(() => {
          const timeId = Date.now()
          this.formatBlankFileData(timeId, file, { name: file.name, fullPath: `/${file.name}` }, this.activeFolderContactId)
          // if (delay + 1 === filteredFiles.length) onComplete()
        }, 10 * delay)
      })
    },

    // (遞迴)資料結構
    readDirectory(directory, node, callback) {
      const timeNode = Date.now()
      this.formatBlankDirectoryData(timeNode, directory, node)
      const reader = directory.createReader()
      reader.readEntries(entries => {
        const processEntries = index => {
          if (index >= entries.length) {
            callback()
            return
          }

          const entry = entries[index]
          if (entry.isFile) {
            entry.file(file => {
              const timeId = Date.now()
              this.formatBlankFileData(timeId, file, entry, timeNode)
              // processEntries(index + 1)
              setTimeout(() => processEntries(index + 1), 20)
            })
          } else if (entry.isDirectory) {
            setTimeout(() => {
              this.readDirectory(entry, timeNode, () => {
                // processEntries(index + 1)
                setTimeout(() => processEntries(index + 1), 20)
              })
            }, 20)
          } else {
            setTimeout(() => processEntries(index + 1), 20)
          }
        }

        processEntries(0)
      })
    },

    // (格式化)檔案
    formatBlankFileData(rId, file, item, node) {
      const fileData = {
        rId,
        name: item.name,
        path: item.fullPath,
        type: file.type,
        size: file.size,
        parent_folder_id: node,
        file,
      }

      const resolveData = this.syncObject(this.blankFileData, fileData)
      this.temporaryFile.push(resolveData)
    },

    // (格式化)資料夾
    formatBlankDirectoryData(rId, directory, node) {
      const directoryData = {
        rId,
        name: directory.name,
        path: directory.fullPath,
        parent_folder_id: node,
        isDirectory: true,
      }

      const resolveData = this.syncObject(this.blankFileData, directoryData)
      this.temporaryFile.push(resolveData)
    },
    // ------------------------------------------------------------------------------------

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return
      this.$nextTick(() => {
        this.$bvModal.hide(this.uploadExplorerId)
        // this.uploadFileList = [] // (暫時)
        // this.isBusy = false // (暫時)

        const isUpload = this.uploadFileList.filter(item => item.id)
        if (this.isHidenRefresh && isUpload.length > 0) this.$emit('reset-data')
        this.uploadFileList = [] // (暫時)
      })
    },

    // 按下彈窗取消
    handleCancel(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$nextTick(() => {
        this.$bvModal.hide(this.uploadExplorerId)
        this.uploadFileList = []
      })
    },

    // (觸發)上傳佇列
    uploadWaiting(fileArray) {
      fileArray.forEach(file => {
        const resolveFile = {
          ...file,
          ...this.blankUploadData,
        }

        if (!this.adminFolderState.activeFolderContactId && (resolveFile.isDirectory === false && !resolveFile.parent_folder_id)) {
          return
        }
        this.uploadFileList.push(resolveFile)
        this.uploadQueueList.push(resolveFile)
      })

      this.$bvModal.show(this.uploadExplorerId)
    },
  },
  setup() {
    const {
      blankFileData,
      blankUploadData,

      isBusy,
      syncObject,
      tableColumns,
      uploadFileList,
      uploadQueueList,

      // Function
      uploadFileQueue,
    } = useFolderUpload()

    const {
      ui,
    } = useFolderSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      ui,
      useAlertToast,
      useHttpCodeAlert,

      blankFileData,
      blankUploadData,
      isBusy,
      syncObject,
      tableColumns,
      uploadFileList,
      uploadQueueList,

      uploadFileQueue,
    }
  },
}
</script>

<style lang="scss" scoped>
.upload-card-content {
  height: 60vh;
  min-height: 300px;
  padding: 10px 0;
  overflow-y:auto;
  overflow-x: hidden;
}
.model-header {
  color: white;
  padding: 5px 0;
}

.title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

// .progress-bar {
//   min-width: 100px;
// }

.upload-card {
  position: relative;
  min-height: 57vh;
  // background-color: red;
  .dropArea {
    // min-height: 50%;
    width: 100%;
    position: absolute;
    z-index: 1000;
    padding: 0 30px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #282828;
    min-height: 70vh;
    opacity: .9;
    text-align: center;
    align-self: center;
    color: #fff;
    &.hidden {
      display: none;
    }
  }
  .upload-label {
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    border-radius: 10px;
    height: 40vh;
    border: #898989 dashed 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .label-title {
      display: block;
      font-size: 2rem;
      font-weight: 900;
      padding: 1rem 0 0.5rem;
    }
    .label-subtitle {
      font-size: 1rem;
    }
  }

  .upload-label-text {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 900;
    padding: 1rem 0 0.5rem;
  }
}

</style>
