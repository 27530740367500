<template>
  <div
    class="folder-application-sidebar"
    :class="{'show': shallOpenApplicationSidebarState}"
  >
    <div class="sidebar-content">
      <div class="animate__animated animate__fadeIn">
        <vue-perfect-scrollbar
          ref="sidebarListContent"
          :settings="perfectScrollbarSettings"
          class="sidebar-list-wrapper"
          style="position: relative;"
        >
          <div
            v-if="adminFolderState.structureFolder.length > 0"
            class="mt-50"
          >
            <div
              v-for="folder in adminFolderState.structureFolder"
              :key="folder.id"
            >
              <!-- {{ folder }} -->
              <FolderStructure
                :structure-id="folder.id"
                :structure="folder"
                :layers="1"
                :is-root="true"
              />
            </div>
          </div>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import FolderStructure from '../comnponent/FolderStructure.vue'
import { useFolderList } from '../useFolder'
// import store from '@/store'

export default {
  components: {
    FolderStructure,
    VuePerfectScrollbar,
  },
  props: {
    shallOpenApplicationSidebarState: {
      type: Boolean,
      required: true,
    },
    activeFolderContactId: {
      type: Number,
      default: null,
    },
    isBusyLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      hoverEl: null,
    }
  },
  computed: {
    adminFolderState() {
      return this.$store.state['admin-folder']
    },
  },
  methods: {
    // (偵測)滑鼠移動事件
    // setHoverElement(event) {
    //   const path = event.composedPath()
    //   let isNode = false
    //   for (let i = 0; i < path.length; i += 1) {
    //     if (path[i].classList.contains('tree-item')) {
    //       this.hoverEl = path[i].id
    //       isNode = true
    //       break
    //     }
    //   }
    //   if (isNode) {
    //     this.$store.commit('admin-folder/UPDATE_HOVEREL_SIDEBAR_STATE', this.hoverEl)
    //     return
    //   }
    //   this.$store.commit('admin-folder/UPDATE_HOVEREL_SIDEBAR_STATE', null)
    // },

    // // (清除)滑鼠事件
    // clearHoverElement() {
    //   this.$store.commit('admin-folder/UPDATE_HOVEREL_SIDEBAR_STATE', null)
    // },

    // (編輯)版塊設定
    // editSetting() {
    //   const positionId = this.hoverEl.split('-')
    //   store.dispatch('pages/getLayoutData', {
    //     id: this.hoverEl,
    //     data: store.state.pages.layoutData,
    //     arr: positionId,
    //   })
    // },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      refreshSidebarList,
    } = useFolderList()

    const testData = [
      {
        id: 4,
        name: '0410',
        type: 'folder',
        children: [
          {
            id: 6,
            name: '0416',
            type: 'folder',
            children: [
              {
                id: 8,
                name: '0418',
                type: 'folder',
                children: [],
              },
              {
                id: 9,
                name: '0419',
                type: 'folder',
                children: [],
              },
            ],
          },
          {
            id: 7,
            name: '0417',
            type: 'folder',
            children: [],
          },
        ],
      },
      {
        id: 5,
        name: '0415',
        type: 'folder',
        children: [],
      },
    ]

    return {
      testData,
      perfectScrollbarSettings,
      refreshSidebarList,
    }
  },
}
</script>
