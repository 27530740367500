<template>
  <div
    class="folder-application-info"
    :class="{'show': shallOpenApplicationInfoState}"
  >
    <div class="info-content">
      <div
        v-if="adminFolderState.focusFolderData"
        class="animate__animated animate__fadeIn"
      >
        <vue-perfect-scrollbar
          ref="sidebarListContent"
          :settings="perfectScrollbarSettings"
          class="sidebar-list-wrapper"
          style="position: relative;"
        >
          <div>
            <!-- {{ adminFolderState.focusFolderData }} -->
            <div class="thumbnail-card">
              <span
                v-if="adminFolderState.focusFolderData.type === 'folder'"
              >
                <FolderImage
                  :size="70"
                  close-folder-image="/admin/images/file/folder/close-folder-1.svg"
                  open-folder-image="/admin/images/file/folder/open-folder.svg"
                />
              </span>

              <span v-else>
                <div class="component-folder-image">
                  <b-img
                    :draggable="false"
                    :src="resolveFileTypeImage(adminFolderState.focusFolderData).image"
                    :alt="resolveFileTypeImage(adminFolderState.focusFolderData).name"
                  />
                </div>
              </span>
            </div>

            <div class="px-1 mt-1">
              <h4>{{ adminFolderState.focusFolderData.name }}</h4>
            </div>

            <div class="p-1">
              <!-- 詳細資料 -->
              <div>
                <h6 class="section-label mb-50">
                  詳細資料
                </h6>
              </div>

              <div v-if="adminFolderState.focusFolderData.type === 'folder'">
                <b-form-group
                  label-cols="4"
                  label="類型"
                  class="m-0 selection-group"
                >
                  <div class="mt-50">
                    檔案資料夾
                  </div>
                </b-form-group>
              </div>

              <div v-if="adminFolderState.focusFolderData.type === 'file'">
                <b-form-group
                  label-cols="4"
                  label="類型"
                  class="m-0 selection-group"
                >
                  <div class="mt-50">
                    {{ adminFolderState.focusFolderData.extension ? `${adminFolderState.focusFolderData.extension.toUpperCase()}檔案` : '未知' }}
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="大小"
                  class="m-0 selection-group"
                >
                  <div class="mt-50">
                    {{ convertBytes(adminFolderState.focusFolderData.size) }}
                  </div>
                </b-form-group>

                <!-- {{ preview_image }} -->
              </div>

              <!-- 創建日期 -->
              <b-form-group
                label-cols="4"
                label="創建日期"
                class="m-0 selection-group"
              >
                <div class="mt-50">
                  {{ adminFolderState.focusFolderData.created_at ? moment(adminFolderState.focusFolderData.created_at).format('YYYY-MM-DD HH:mm') : '---' }}
                </div>
              </b-form-group>

              <!-- 最後修改 -->
              <b-form-group
                label-cols="4"
                label="最後修改"
                class="m-0 selection-group"
              >
                <div class="mt-50">
                  {{ adminFolderState.focusFolderData.updated_at ? moment(adminFolderState.focusFolderData.updated_at).format('YYYY-MM-DD HH:mm') : '---' }}
                </div>
              </b-form-group>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BImg } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'
import { useFolderSetting } from '../useFolder'
import FolderImage from '../comnponent/FolderImage.vue'

export default {
  components: {
    BImg,
    BFormGroup,
    VuePerfectScrollbar,
    FolderImage,
  },
  props: {
    shallOpenApplicationInfoState: {
      type: Boolean,
      required: true,
    },
    // activeFolderContactId: {
    //   type: Number,
    //   default: null,
    // },
  },
  computed: {
    adminFolderState() {
      return this.$store.state['admin-folder']
    },
    // currentLocalFolder() {
    //   const currentLocal = JSON.parse(JSON.stringify(this.adminFolderState.currentLocalPath))
    //   if (currentLocal.length > 0) {
    //     const lastElement = currentLocal.pop()
    //     return lastElement
    //   }
    //   return null
    // },
  },
  methods: {
    moment,
    // KB轉換
    convertBytes(bytes) {
      const kilobytes = (bytes / 1024).toFixed(1)
      const gigabytes = (kilobytes / (1024 * 1024)).toFixed(1)
      const megabytes = (kilobytes / 1024).toFixed(1)

      if (kilobytes >= 1024 * 1024) return `${gigabytes.toLocaleString()} GB`
      if (kilobytes >= 1024) return `${megabytes.toLocaleString()} MB`
      return `${kilobytes.toLocaleString()} KB`
    },

    // (顯示)檔案類型
    resolveFileTypeImage(item) {
      const matchingItem = this.folderType.find(el => el.type === item.extension)
      if (item.type === 'file' && item.preview_image) {
        matchingItem.image = item.preview_image
      }
      if (matchingItem) return matchingItem
      return { name: '未知', type: null, image: '/admin/images/file/type/empty.svg' }
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      folderType,
    } = useFolderSetting()

    return {
      folderType,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss" scoped>
.thumbnail-card {
  background-color:#adadad30;
  // width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .component-folder-image {
    img {
      max-height: 70px;
      width: 100%;
      max-width: 70px;
      // user-select: none;
    }
  }
}
</style>
