import { ref } from '@vue/composition-api' // , watch, computed
import axios from '@axios'
import {
  useCommenSettings, useAlert, // useTableComponent,
} from '@/libs/mixins/index'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
// import router from '@/router'

export const useFolderMixins = {
  methods: {
    isFileTypeSame(target, item) {
      if (item.id === target.id && item.type === target.type) return true
      return false
    },
  },
}

export const useFolderSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const imagePath = '/admin/images/file/type'

  const folderType = [
    { name: 'TXT', type: 'txt', image: `${imagePath}/039-txt.svg` },
    { name: 'SQL', type: 'sql', image: `${imagePath}/001-sql.svg` },
    { name: 'SQL', type: 'sql', image: `${imagePath}/001-sql.svg` },
    { name: 'FLA', type: 'flz', image: `${imagePath}/002-fla.svg` },
    { name: 'EML', type: 'eml', image: `${imagePath}/003-eml.svg` },
    { name: 'DOC', type: 'doc', image: `${imagePath}/004-doc.svg` },
    { name: 'PPT', type: 'ppt', image: `${imagePath}/005-ppt.svg` },
    { name: 'XLS', type: 'xls', image: `${imagePath}/006-xls.svg` },
    { name: 'LOG', type: 'log', image: `${imagePath}/007-log.svg` },
    { name: 'JAR', type: 'jar', image: `${imagePath}/008-jar.svg` },
    { name: 'SVG', type: 'svg', image: `${imagePath}/009-svg.svg` },
    { name: '3DS', type: '3ds', image: `${imagePath}/010-3ds.svg` },
    { name: 'EPS', type: 'eps', image: `${imagePath}/011-eps.svg` },
    { name: 'EPS', type: 'eps', image: `${imagePath}/011-eps.svg` },
    { name: 'HTML', type: 'html', image: `${imagePath}/012-html.svg` },
    { name: 'AI', type: 'ai', image: `${imagePath}/013-ai.svg` },
    { name: 'PHP', type: 'php', image: `${imagePath}/014-php.svg` },
    { name: 'TTF', type: 'ttf', image: `${imagePath}/015-ttf.svg` },
    { name: 'PSD', type: 'psd', image: `${imagePath}/016-psd extension.svg` },
    { name: 'WAV', type: 'wav', image: `${imagePath}/017-wav.svg` },
    { name: 'DLL', type: 'dll', image: `${imagePath}/018-dll.svg` },
    { name: 'APK', type: 'apk', image: `${imagePath}/019-apk.svg` },
    { name: 'URL', type: 'url', image: `${imagePath}/020-url.svg` },
    { name: 'PUB', type: 'pub', image: `${imagePath}/021-pub.svg` },
    { name: 'FLV', type: 'flv', image: `${imagePath}/022-flv.svg` },
    { name: 'PDF', type: 'pdf', image: `${imagePath}/023-pdf.svg` },
    { name: 'OTF', type: 'otf', image: `${imagePath}/024-otf.svg` },
    { name: 'OBJ', type: 'obj', image: `${imagePath}/025-obj.svg` },
    { name: 'XML', type: 'xml', image: `${imagePath}/026-xml.svg` },
    { name: 'CVS', type: 'cvs', image: `${imagePath}/027-csv.svg` },
    { name: 'JS', type: 'js', image: `${imagePath}/028-js.svg` },
    { name: 'SRT', type: 'srt', image: `${imagePath}/029-srt.svg` },
    { name: 'RAR', type: 'rar', image: `${imagePath}/030-rar.svg` },
    { name: 'PNG', type: 'png', image: `${imagePath}/031-png.svg` },
    { name: 'C++', type: '', image: `${imagePath}/032-c++.svg` },
    { name: 'C++', type: '', image: `${imagePath}/032-c++.svg` },
    { name: 'TIF', type: 'tif', image: `${imagePath}/033-tif.svg` },
    { name: 'MP3', type: 'mp3', image: `${imagePath}/034-mp3.svg` },
    { name: 'CSS', type: 'css', image: `${imagePath}/036-css.svg` },
    { name: 'EXE', type: 'exe', image: `${imagePath}/036-exe.svg` },
    { name: 'C#', type: '', image: `${imagePath}/037-c sharp.svg` },
    { name: '3GP', type: '3gp', image: `${imagePath}/038-3gp.svg` },
    { name: 'ZIP', type: 'zip', image: `${imagePath}/040-zip.svg` },
    { name: 'DWG', type: 'dwg', image: `${imagePath}/041-dwg.svg'` },
    { name: 'DWG', type: 'dwg', image: `${imagePath}/041-dwg.svg` },
    { name: 'MDB', type: 'mdb', image: `${imagePath}/042-mdb.svg` },
    { name: 'MDB', type: 'mdb', image: `${imagePath}/042-mdb.svg` },
    { name: 'DAT', type: 'dat', image: `${imagePath}/043-dat.svg` },
    { name: 'DAT', type: 'dat', image: `${imagePath}/043-dat.svg` },
    { name: 'ISO', type: 'iso', image: `${imagePath}/044-iso.svg` },
    { name: 'JPEG', type: 'jpeg', image: `${imagePath}/045-jpeg.svg` },
    { name: 'CDR', type: 'cdr', image: `${imagePath}/046-cdr.svg` },
    { name: 'GIF', type: 'gif', image: `${imagePath}/047-gif.svg` },
    { name: 'MP4', type: 'mp4', image: `${imagePath}/048-mp4.svg` },
    { name: 'ASP', type: 'asp', image: `${imagePath}/049-asp.svg` },
    { name: 'ASP', type: 'asp', image: `${imagePath}/049-asp.svg` },
    { name: 'MOV', type: 'mov', image: `${imagePath}/050-mov.svg` },
  ]

  // const folderType = [
  //   { name: 'TXT', type: 'txt', mime: 'text/plain', image: `${imagePath}/039-txt.svg` },
  //   { name: 'SQL', type: 'sql', mime: 'application/sql', image: `${imagePath}/001-sql.svg` },
  //   { name: 'SQL', type: 'sql', mime: 'text/sql', image: `${imagePath}/001-sql.svg` },
  //   { name: 'FLA', type: 'flz', mime: 'application/x-fla', image: `${imagePath}/002-fla.svg` },
  //   { name: 'EML', type: 'eml', mime: 'message/rfc822', image: `${imagePath}/003-eml.svg` },
  //   { name: 'DOC', type: 'doc', mime: 'application/msword', image: `${imagePath}/004-doc.svg` },
  //   { name: 'PPT', type: 'ppt', mime: 'application/vnd.ms-powerpoint', image: `${imagePath}/005-ppt.svg` },
  //   { name: 'XLS', type: 'xls', mime: 'application/vnd.ms-excel', image: `${imagePath}/006-xls.svg` },
  //   { name: 'LOG', type: 'log', mime: 'text/plain', image: `${imagePath}/007-log.svg` },
  //   { name: 'JAR', type: 'jar', mime: 'application/java-archive', image: `${imagePath}/008-jar.svg` },
  //   { name: 'SVG', type: 'svg', mime: 'image/svg+xml', image: `${imagePath}/009-svg.svg` },
  //   { name: '3DS', type: '3ds', mime: 'image/x-3ds', image: `${imagePath}/010-3ds.svg` },
  //   { name: 'EPS', type: 'eps', mime: 'application/eps', image: `${imagePath}/011-eps.svg` },
  //   { name: 'EPS', type: 'eps', mime: 'application/postscript', image: `${imagePath}/011-eps.svg` },
  //   { name: 'HTML', type: 'html', mime: 'text/html', image: `${imagePath}/012-html.svg` },
  //   { name: 'AI', type: 'ai', mime: 'application/postscript', image: `${imagePath}/013-ai.svg` },
  //   { name: 'PHP', type: 'php', mime: 'application/x-httpd-php', image: `${imagePath}/014-php.svg` },
  //   { name: 'TTF', type: 'ttf', mime: 'font/ttf', image: `${imagePath}/015-ttf.svg` },
  //   { name: 'PSD', type: 'psd', mime: 'image/vnd.adobe.photoshop', image: `${imagePath}/016-psd extension.svg` },
  //   { name: 'WAV', type: 'wav', mime: 'audio/wav', image: `${imagePath}/017-wav.svg` },
  //   { name: 'DLL', type: 'dll', mime: 'application/x-msdownload', image: `${imagePath}/018-dll.svg` },
  //   { name: 'APK', type: 'apk', mime: 'application/vnd.android.package-archive', image: `${imagePath}/019-apk.svg` },
  //   { name: 'URL', type: 'url', mime: 'application/internet-shortcut', image: `${imagePath}/020-url.svg` },
  //   { name: 'PUB', type: 'pub', mime: 'application/x-mspublisher', image: `${imagePath}/021-pub.svg` },
  //   { name: 'FLV', type: 'flv', mime: 'video/x-flv', image: `${imagePath}/022-flv.svg` },
  //   { name: 'PDF', type: 'pdf', mime: 'application/pdf', image: `${imagePath}/023-pdf.svg` },
  //   { name: 'OTF', type: 'otf', mime: 'font/otf', image: `${imagePath}/024-otf.svg` },
  //   { name: 'OBJ', type: 'obj', mime: 'application/x-tgif', image: `${imagePath}/025-obj.svg` },
  //   { name: 'XML', type: 'xml', mime: 'application/xml', image: `${imagePath}/026-xml.svg` },
  //   { name: 'CVS', type: 'cvs', mime: 'text/csv', image: `${imagePath}/027-csv.svg` },
  //   { name: 'JS', type: 'js', mime: 'application/javascript', image: `${imagePath}/028-js.svg` },
  //   { name: 'SRT', type: 'srt', mime: 'text/plain', image: `${imagePath}/029-srt.svg` },
  //   { name: 'RAR', type: 'rar', mime: 'application/x-rar-compressed', image: `${imagePath}/030-rar.svg` },
  //   { name: 'PNG', type: 'png', mime: 'image/png', image: `${imagePath}/031-png.svg` },
  //   { name: 'C++', type: '', mime: 'text/plain', image: `${imagePath}/032-c++.svg` },
  //   { name: 'C++', type: '', mime: 'text/x-c++src', image: `${imagePath}/032-c++.svg` },
  //   { name: 'TIF', type: 'tif', mime: 'image/tiff', image: `${imagePath}/033-tif.svg` },
  //   { name: 'MP3', type: 'mp3', mime: 'audio/mpeg', image: `${imagePath}/034-mp3.svg` },
  //   { name: 'CSS', type: 'css', mime: 'text/css', image: `${imagePath}/036-css.svg` },
  //   { name: 'EXE', type: 'exe', mime: 'application/vnd.microsoft.portable-executable', image: `${imagePath}/036-exe.svg` },
  //   { name: 'C#', type: '', mime: 'text/x-csharp', image: `${imagePath}/037-c sharp.svg` },
  //   { name: '3GP', type: '3gp', mime: 'video/3gpp', image: `${imagePath}/038-3gp.svg` },
  //   { name: 'ZIP', type: 'zip', mime: 'application/zip', image: `${imagePath}/040-zip.svg` },
  //   { name: 'DWG', type: 'dwg', mime: 'application/vnd.autocad.dwg', image: `${imagePath}/041-dwg.svg'` },
  //   { name: 'DWG', type: 'dwg', mime: 'image/vnd.dwg', image: `${imagePath}/041-dwg.svg` },
  //   { name: 'MDB', type: 'mdb', mime: 'application/msaccess', image: `${imagePath}/042-mdb.svg` },
  //   { name: 'MDB', type: 'mdb', mime: 'application/x-msaccess', image: `${imagePath}/042-mdb.svg` },
  //   { name: 'DAT', type: 'dat', mime: 'text/plain', image: `${imagePath}/043-dat.svg` },
  //   { name: 'DAT', type: 'dat', mime: 'application/octet-stream', image: `${imagePath}/043-dat.svg` },
  //   { name: 'ISO', type: 'iso', mime: 'application/x-iso9660-image', image: `${imagePath}/044-iso.svg` },
  //   { name: 'JPEG', type: 'jpeg', mime: 'image/jpeg', image: `${imagePath}/045-jpeg.svg` },
  //   { name: 'CDR', type: 'cdr', mime: 'application/vnd.corel-draw', image: `${imagePath}/046-cdr.svg` },
  //   { name: 'GIF', type: 'gif', mime: 'image/gif', image: `${imagePath}/047-gif.svg` },
  //   { name: 'MP4', type: 'mp4', mime: 'video/mp4', image: `${imagePath}/048-mp4.svg` },
  //   { name: 'ASP', type: 'asp', mime: 'text/asp', image: `${imagePath}/049-asp.svg` },
  //   { name: 'ASP', type: 'asp', mime: 'application/x-asp', image: `${imagePath}/049-asp.svg` },
  //   { name: 'MOV', type: 'mov', mime: 'video/quicktime', image: `${imagePath}/050-mov.svg` },
  // ]

  const ui = {
    directoryList: [{
      true: '資料夾', false: '檔案',
    }, {
      true: 'light-warning', false: 'light-info',
    }],
  }

  // const getMetaListData = () => store.dispatch('api/getMetaList')
  // const getConfigData = (...arg) => store.dispatch('api/getConfigData', ...arg)

  const blankDepartmentInfo = {
    id: null,
    name: null,
  }

  return {
    ui,
    folderType,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,

    blankDepartmentInfo,
  }
}

export const useFolderList = () => {
  // const {
  //   refDataListTable,
  //   perPage,
  //   totalNum,
  //   currentPage,
  //   perPageOptions,
  //   searchQuery,
  //   sortBy,
  //   isSortDirDesc,
  //   tableColumns,
  //   timeRange,
  // } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  // const {
  //   blankSimpleInfo,
  // } = useFolderSetting()

  const isInitBusy = ref(true)
  const isBusyLoading = ref(false)
  const isBusy = ref(false)
  const isDownloadBusy = ref(false)

  const blankListData = {
    id: null,
    name: '未命名',
    folder_size: '',
    parent_folder_id: null,
    permission: null,
    type: null, // folder, file
    user_id: null,
    created_at: null,
    updated_at: null,

    folder_id: null,
    extension: null,
    size: 0,
    mime: null,
    path: null,
    preview_image: null,
  }

  // const blankFileData = {
  //   extension: null,
  //   size: 0,
  //   mime: null,
  //   path: null,
  //   preview_img: null, // folder, file
  // }
  // const blankUserInfoData = {
  //   id: null,
  //   name: '未知',
  // }

  const blankStructureData = {
    id: null,
    name: '未命名',
    type: null,
    children: [],
  }

  const blankEditData = {
    id: null,
    type: null,
    oldName: null,
    newName: null,
  }

  // const setTodoCreate = (...arg) => store.dispatch('admin-todo/setTodoCreate', ...arg)
  // const setTodoCreateQuick = (...arg) => store.dispatch('admin-todo/setTodoCreateQuick', ...arg)
  // const setTodoUpdate = (...arg) => store.dispatch('admin-todo/setTodoUpdate', ...arg)
  // const setTodoDelete = (...arg) => store.dispatch('admin-todo/setTodoDelete', ...arg)
  // const setTodoViewUpdate = (...arg) => store.dispatch('admin-todo/setTodoViewUpdate', ...arg)
  // const setTodoCache = (...arg) => store.dispatch('admin-todo/setTodoCache', ...arg)
  // const setTodoComplete = (...arg) => store.dispatch('admin-todo/setTodoComplete', ...arg)
  // const setTodoReview = (...arg) => store.dispatch('admin-todo/setTodoReview', ...arg)

  // (處理)圖示結構
  const initStructureData = folderList => {
    const resolveList = []
    folderList.forEach(folder => {
      if (folder.type === 'folder') {
        const resolveData = syncObject(blankStructureData, folder)
        resolveList.push(resolveData)
      }
    })
    return resolveList
  }

  // (處理)資料夾
  const resolveFolderList = folderList => {
    const resolveListData = folderList.map(item => {
      const resolveData = syncObject(blankListData, item)
      // resolveData.last_update_user_info = syncObject(blankUserInfoData, item.user_id ? item.last_update_user_info : {})
      return resolveData
    })

    return resolveListData
  }

  // (初始化)雲端空間列表
  const getFolderListInitData = () => {
    store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', true)
    store.dispatch('admin-folder/getFolderList', {
      _: Date.now(),
    })
      .then(response => {
        const { data } = response.data.data
        const resolveListData = resolveFolderList(data)

        // blankStructureData
        const resolveStructureData = initStructureData(resolveListData)

        store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)
        store.commit('admin-folder/UPDATE_STRUCTURE_FOLDER_LIST', resolveStructureData)
        store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  // (重新整理)側欄資料夾
  const refreshSidebarList = (type, resolveData) => {
    if (!type) return
    const structureFolder = JSON.parse(JSON.stringify(store.state['admin-folder'].structureFolder))

    const refreshStructure = () => {
      store.dispatch('admin-folder/getFolderList', {
        _: Date.now(),
      })
        .then(response => {
          const { data } = response.data.data
          const resolveListData = resolveFolderList(data)
          const resolveStructureData = initStructureData(resolveListData)
          store.commit('admin-folder/UPDATE_STRUCTURE_FOLDER_LIST', [])
          setTimeout(() => {
            store.commit('admin-folder/UPDATE_STRUCTURE_FOLDER_LIST', resolveStructureData)
          }, 100)
        })
    }

    const deleteStructure = (structure, id) => {
      for (let i = 0; i < structure.length; i += 1) {
        const node = structure[i]
        if (node.id === id) {
          structure.splice(i, 1)
          return true
        }
        if (node.children && node.children.length > 0) {
          const nodeRemoved = deleteStructure(node.children, id)
          if (nodeRemoved) {
            return true
          }
        }
      }
      return false
    }

    const updateStructure = (structure, id, newName) => {
      for (let i = 0; i < structure.length; i += 1) {
        const node = structure[i]
        if (node.id === id) {
          node.name = newName
          return true
        }
        if (node.children && node.children.length > 0) {
          const nodeUpdated = updateStructure(node.children, id, newName)
          if (nodeUpdated) {
            return true
          }
        }
      }
      return false
    }

    const createStructure = (structure, id, child) => {
      for (let i = 0; i < structure.length; i += 1) {
        const node = structure[i]
        if (node.id === id) {
          node.children.push(child)
          return true
        }
        if (node.children && node.children.length > 0) {
          const nodeUpdated = updateStructure(node.children, id, child)
          if (nodeUpdated) {
            return true
          }
        }
      }
      return false
    }

    const deleteMutiStructure = (structure, array) => {
      for (let i = structure.length - 1; i >= 0; i -= 1) {
        const node = structure[i]
        if (array.includes(node.id)) {
          structure.splice(i, 1)
        } else if (node.children && node.children.length > 0) {
          deleteMutiStructure(node.children, array)
        }
      }
    }

    switch (type) {
      case 'refresh': {
        refreshStructure()
        break
      }
      case 'delete': {
        deleteStructure(structureFolder, resolveData.id)
        store.commit('admin-folder/UPDATE_STRUCTURE_FOLDER_LIST', structureFolder)
        break
      }
      case 'delete-muti': {
        deleteMutiStructure(structureFolder, resolveData.array)
        store.commit('admin-folder/UPDATE_STRUCTURE_FOLDER_LIST', structureFolder)
        break
      }
      case 'update': {
        updateStructure(structureFolder, resolveData.id, resolveData.newName)
        store.commit('admin-folder/UPDATE_STRUCTURE_FOLDER_LIST', structureFolder)
        break
      }
      case 'create': {
        createStructure(structureFolder, resolveData.id, resolveData.child)
        store.commit('admin-folder/UPDATE_STRUCTURE_FOLDER_LIST', structureFolder)
        break
      }
      default: {
        break
      }
    }
  }

  // (更新)子結構內容
  const updateChildrenById = (structure, searchId, newChildren) => {
    for (let i = 0; i < structure.length; i += 1) {
      const item = structure[i]

      if (item.id === searchId) {
        item.children = newChildren
        return true
      }

      if (item.children && item.children.length > 0) {
        const found = updateChildrenById(item.children, searchId, newChildren)
        if (found) {
          return true
        }
      }
    }
    return false
  }

  // (更新)當前結構內容
  const updateCurrentLocalPath = (structure, searchId, parentId = null, path = []) => {
    for (let i = 0; i < structure.length; i += 1) {
      const node = structure[i]
      if (node.id === searchId) {
        return [
          ...path,
          { ...node, parent_folder_id: parentId },
        ]
      }
      if (node.children && node.children.length > 0) {
        const result = updateCurrentLocalPath(node.children, searchId, node.id, [...path, { ...node, parent_folder_id: parentId }])
        if (result) {
          return result
        }
      }
    }
    return null
  }

  // (打開)資料夾
  const getOpenFolderChildren = (folder, callback) => {
    const adminFolderState = store.state['admin-folder']
    store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', true)
    store.commit('admin-folder/UPDATE_ACTIVE_FOLDER_ID', folder ? folder.id : null)
    if (!folder) {
      store.dispatch('admin-folder/getFolderList', {
        _: Date.now(),
      })
        .then(response => {
          const { data } = response.data.data
          const resolveListData = resolveFolderList(data)
          // this.$store.commit('admin-folder/UPDATE_ACTIVE_FOLDER_ID', this.structureId)
          store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)
          callback()
        })
        .catch(error => {
          useHttpCodeAlert(error.response)
        })
    } else {
      store.dispatch('admin-folder/getFolderList', {
        folder_id: folder.id,
      })
        .then(response => {
          const { data } = response.data.data
          const resolveListData = resolveFolderList(data)
          store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)
          const resolveStructureData = initStructureData(resolveListData)

          const { structureFolder } = adminFolderState
          updateChildrenById(structureFolder, folder.id, resolveStructureData)
          callback()
        })
        .catch(error => {
          useHttpCodeAlert(error.response)
        })
    }
  }

  // (查詢)資料夾結構
  const getSidebarChildren = id => {
    store.dispatch('admin-folder/getFolderList', {
      folder_id: id,
    })
      .then(response => {
        const { data } = response.data.data
        const resolveListData = resolveFolderList(data)
        const resolveStructureData = initStructureData(resolveListData)
        const adminFolderState = store.state['admin-folder']
        const { structureFolder } = adminFolderState
        updateChildrenById(structureFolder, id, resolveStructureData)
        // updateCurrentLocalPath(id)
      })
  }

  // (更新)檔案
  const setFolderUpdate = callback => {
    const adminFolderState = store.state['admin-folder']

    if (adminFolderState.renameFolderData.type === 'folder') {
      store.dispatch('admin-folder/setFolderUpdate', {
        folder_id: adminFolderState.renameFolderData.id,
        data: {
          name: adminFolderState.renameFolderData.newName,
        },
      })
        .then(() => {
          callback()
        })
        .catch(error => {
          useHttpCodeAlert(error.response)
          isBusy.value = false
        })
    } else {
      store.dispatch('admin-folder/setFileUpdate', {
        folder_id: adminFolderState.activeFolderContactId,
        file_id: adminFolderState.renameFolderData.id,
        data: {
          name: adminFolderState.renameFolderData.newName,
        },
      })
        .then(() => {
          callback()
          isBusy.value = false
        })
        .catch(error => {
          useHttpCodeAlert(error.response)
          isBusy.value = false
        })
    }
  }

  // (移除)檔案
  const setFolderDelete = (type, array, callback) => {
    const resolveData = {}

    if (type === 'folder') {
      resolveData.folder_id = array
    } else {
      resolveData.file_id = array
    }

    store.dispatch('admin-folder/setFolderDelete', {
      data: resolveData,
    })
      .then(() => {
        callback()
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        isBusy.value = false
      })
  }

  // (移除)多選檔案
  const setFolderMutiDelete = (folderArray, fileArray, callback) => {
    const resolveData = {
      folder_id: folderArray,
      file_id: fileArray,
    }

    store.dispatch('admin-folder/setFolderDelete', {
      data: resolveData,
    })
      .then(() => {
        callback()
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        isBusy.value = false
      })
  }

  // (下載)檔案
  const getFileDownload = (file, callback) => {
    if (isDownloadBusy.value) return
    isDownloadBusy.value = true
    store.dispatch('admin-folder/getFileDownload', {
      folder_id: file.folder_id,
      file_id: file.id,
    })
      .then(response => {
        const { url } = response.data.data
        callback(url)
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        isDownloadBusy.value = false
      })
  }

  return {
    isBusy,
    isInitBusy,
    isBusyLoading,

    blankEditData,

    updateCurrentLocalPath,
    refreshSidebarList,

    useAlertToast,
    getFolderListInitData,
    getOpenFolderChildren,
    getSidebarChildren,
    setFolderUpdate,
    setFolderDelete,
    setFolderMutiDelete,
    getFileDownload,
    // refetchTable,

    // setTodoCreate,
    // setTodoCreateQuick,
    // setTodoUpdate,
    // setTodoDelete,
    // setTodoViewUpdate,
    // setTodoCache,
    // setTodoComplete,
    // setTodoReview,
    useHttpCodeAlert,
    syncObject,
  }
}

export const useFolderUpload = () => {
  const {
    syncObject,
  } = useCommenSettings()

  const blankFileData = {
    id: null,
    rId: null,
    name: null,
    path: null,
    type: null,
    size: null,
    parent_folder_id: null,
    isDirectory: false,
    file: null,
  }

  const blankUploadData = {
    isUpload: false,
    isBusy: true,
    bar: 0,
    error: null,
  }

  const tableColumns = [
    { label: '名稱', key: 'file_name' },
    // { label: '類型', key: 'type' },
    { label: '檔案大小', key: 'size' },
    { label: '上傳狀態', key: 'state' },
  ]

  const chunkSize = 1048576 // 1MB
  // const chunkTotal = ref(0)

  const uploadFileList = ref([])
  const uploadQueueList = ref([])
  const isBusy = ref(false)

  const setFileUpload = data => store.dispatch('admin-folder/setFileUpload', data)
  const getFileStatus = (...arg) => store.dispatch('admin-folder/getFileStatus', ...arg)

  // (成功)上傳
  const successDataBack = (rId, response) => {
    uploadQueueList.value = uploadQueueList.value.map(item => {
      if (!item.id && item.parent_folder_id === rId) {
        return {
          ...item,
          parent_folder_id: response.id,
        }
      }
      return item
    })

    uploadFileList.value = uploadFileList.value.map(item => {
      if (item.rId === rId) {
        return {
          ...item,
          rId: null,
          id: response.id,
          isUpload: false,
          isBusy: false,
          bar: 100,
          error: null,
        }
      }

      if (!item.id && item.parent_folder_id === rId) {
        return {
          ...item,
          parent_folder_id: response.id,
        }
      }
      return item
    })
  }

  // (失敗)上傳
  const errorDataBack = (rId, response) => {
    uploadFileList.value = uploadFileList.value.map(item => {
      if (item.rId === rId) {
        return {
          ...item,
          rId: null,
          isBusy: false,
          error: response ? response.message : '上傳失敗',
        }
      }
      return item
    })
  }

  // (開始)上傳
  const startUpload = rId => {
    const index = uploadFileList.value.findIndex(obj => obj.rId === rId)
    if (index !== -1) uploadFileList.value[index].isUpload = true
  }

  // (上傳)區塊
  const uploadFileChunk = (uploadId, chunk, file, callback) => {
    const uploadFile = file.file
    const formData = new FormData()

    const startOffset = chunk * chunkSize
    const endOffset = Math.min(startOffset + chunkSize, file.size)
    const chunkData = uploadFile.slice(startOffset, endOffset)
    formData.set('data', chunkData)
    formData.append('index', chunk)

    const uploadData = {
      folder_id: file.parent_folder_id,
      log_id: uploadId,
      data: formData,
    }

    setFileUpload(uploadData)
      .then(response => {
        const { index } = response.data.data
        // successDataBack(file.rId, response.data.data)
        const success = {
          log_id: uploadId,
          index,
          state: true,
        }
        callback(success)
      })
      .catch(() => {
        const error = {
          log_id: uploadId,
          state: false,
        }
        callback(error)
      })

    // (模擬回傳)
    // setTimeout(() => {
    //   const success = {
    //     log_id: uploadId,
    //     data: {
    //       id: Date.now(),
    //     },
    //     state: true,
    //   }

    //   // const error = {
    //   //   data: {},
    //   //   state: false,
    //   // }
    //   callback(success)
    // }, 200)
  }

  // (創建)資料夾
  const setFolderCreate = (file, callback) => {
    startUpload(file.rId)

    axios('/admin/file-system/folders', {
      method: 'POST',
      headers: {
        'Access-Token': useJwt.getToken(),
      },
      data: {
        name: file.name,
        parent_folder_id: file.parent_folder_id,
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        const index = uploadFileList.value.findIndex(obj => obj.rId === file.rId)
        if (index !== -1) uploadFileList.value[index].bar = percentCompleted - 1
      },
    })
      .then(response => {
        successDataBack(file.rId, response.data.data)
        callback()
      })
      .catch(error => {
        errorDataBack(file.rId, error.response.data ? error.response.data : { message: '上傳失敗' })
        callback()
      })
  }

  // (新增)檔案
  const setFileCreate = (file, callback) => {
    startUpload(file.rId)
    if (!file.parent_folder_id) {
      errorDataBack(file.rId, { message: '未指定資料夾' })
      callback()
      return
    }

    const chunkTotal = Math.ceil(file.size / chunkSize)

    const uploadInfo = {
      name: file.name,
      total_chunk: chunkTotal,
    }

    store.dispatch('admin-folder/setFileCreate', {
      folder_id: file.parent_folder_id,
      data: uploadInfo,
    })
      .then(response => {
        const uploadId = response.data.data.id
        if (!uploadId) {
          errorDataBack(file.rId, { message: '上傳失敗' })
          callback()
          return
        }

        const onComplete = async () => {
          const pollStatus = () => getFileStatus({ folder_id: file.parent_folder_id, file_id: uploadId })
            .then(responseStatus => {
              const { action } = responseStatus.data.data

              if (action === 'finish') {
                return Promise.resolve(true)
              }
              if (action === 'wait') {
                return new Promise(resolve => setTimeout(resolve, 1000)) // 等待 1 秒
                  .then(pollStatus)
              }
              return Promise.resolve(false)
            })
            .catch(() => {
              errorDataBack(file.rId, { message: '上傳失敗' })
              callback()
            })

          pollStatus().then(state => {
            if (state) {
              successDataBack(file.rId, { id: uploadId })
              callback()
            } else {
              errorDataBack(file.rId, { message: '轉檔失敗' })
              callback()
            }
          })
          // successDataBack(file.rId, { id: uploadId }) // response.data.data
          // callback()
        }

        const processItems = (pointId, start) => {
          if (start >= chunkTotal) {
            setTimeout(() => onComplete(), 200)
            return
          }

          uploadFileChunk(pointId, start, file, callbackData => { // 指標, 區段, 檔案, 回調
            if (!callbackData.state) {
              errorDataBack(file.rId, { message: '上傳失敗' })
              callback()
            }

            if (callbackData.state) {
              // (上傳進度)
              const percentCompleted = Math.round(((start + 1) / chunkTotal) * 100)
              const index = uploadFileList.value.findIndex(obj => obj.rId === file.rId)
              uploadFileList.value[index].bar = percentCompleted - 1
              setTimeout(() => processItems(callbackData.log_id, callbackData.index), 20)
            }
          })
        }

        processItems(uploadId, 0)
      })
      .catch(error => {
        errorDataBack(file.rId, error.response.data ? error.response.data : { message: '上傳失敗' })
        callback()
      })
  }

  // (上傳)佇列
  const uploadFileQueue = () => {
    if (uploadQueueList.value.length === 0) {
      isBusy.value = false
      return
    }
    const file = uploadQueueList.value[0]

    if (file.isDirectory) {
      setFolderCreate(file, () => {
        uploadQueueList.value.shift()
        uploadFileQueue()
      })
    } else {
      setFileCreate(file, () => {
        uploadQueueList.value.shift()
        uploadFileQueue()
      })
    }
  }

  return {
    blankFileData,
    blankUploadData,
    syncObject,

    isBusy,

    tableColumns,
    uploadFileList,
    uploadQueueList,
    uploadFileQueue,
  }
}
