<template>
  <div
    class="gallery"
  >
    <!-- {{ adminFolderState.focusFolderData }} -->
    <div class="gallery-area animate__animated animate__fadeIn">
      <div
        v-for="item in currentFolderFilter"
        :key="`${item.type}-${item.id}`"
        v-contextmenu:gallery-menu-action
        class="thumbnail"
        :class="{
          'thumbnail-hover': item.id === hoverItemId,
          'thumbnail-focus': adminFolderState.focusFolderData && item.id === adminFolderState.focusFolderData.id && adminFolderState.selectedMutiFolders.length === 0
        }"
        @mouseover="hoverItemId = item.id"
        @mouseout="hoverItemId = null"
        @contextmenu.prevent="(event) => {
          rightClick = item
        }"
        @click="setFocusFolderData($event, item)"
      >
        <!-- @click="handleClickOpen(item)" -->
        <div
          class="thumbnail-image"
          @click="handleClickOpenFolder(item)"
        >
          <span
            v-if="item.type === 'folder'"
          >
            <FolderImage
              :is-active="item.id === hoverItemId"
              :size="50"
              close-folder-image="/admin/images/file/folder/close-folder-1.svg"
              open-folder-image="/admin/images/file/folder/open-folder.svg"
            />
          </span>

          <span v-else>
            <div class="component-folder-image">
              <b-img
                :draggable="false"
                :src="resolveFileTypeImage(item).image"
                :alt="resolveFileTypeImage(item).name"
              />

              <!-- <b-img
                :src="openFolderImage"
                :style="{ height: size + 'px', weight: size + 'px' }"
              /> -->
            </div>
          </span>
        </div>

        <div
          class="title"
          @click="handleClickEditName(item)"
        >
          <div v-if="!isFileTypeSame(adminFolderState.renameFolderData, item)">
            {{ resolveFileNameLong(item.name, 10) }}
            <!-- / {{ item.id }} -->
          </div>

          <div
            v-else
            class="title-input"
          >
            <b-form-input
              v-model="adminFolderState.renameFolderData.newName"
              size="sm"
              :placeholder="item.name"
              autofocus
              @keydown.enter.prevent="submitConfirmName"
            />
          </div>
        </div>

        <div
          v-if="adminFolderState.selectedMutiFolders.length > 0"
          class="thumbnail-mask"
          :class="{ 'thumbnail-content-selected-area': isFolderInSelected(item)}"
          @click="submitSelectedFolder(item)"
        />
        <!-- v-if="isFolderInSelected(item)" -->
      </div>

      <div
        v-if="adminFolderState.currentFolder.length === 0"
        class="d-flex justify-content-center w-100"
      >
        <span>這個資料夾是空的</span>
      </div>
    </div>

    <v-contextmenu
      ref="gallery-menu-action"
      :class="$store.state.appConfig.layout.skin === 'dark' ? 'my-contextmenu-dark' : 'my-contextmenu'"
    >
      <div>
        <v-contextmenu-item
          class="dropdown-item-area"
          @click="submitShowFileInfo(rightClick)"
        >
          <b-img
            src="/admin/images/file/action/info.svg"
            class="dropdown-item-btn-image mr-50"
          />
          <span>顯示詳情</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          v-if="rightClick.type !== 'folder'"
          class="dropdown-item-area"
          @click="submitDownloadFile(rightClick)"
        >
          <b-img
            src="/admin/images/file/action/download.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>下載</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          class="dropdown-item-area"
        >
          <b-img
            src="/admin/images/file/action/scissors.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>剪下</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          class="dropdown-item-area"
          @click="submitRenameFolder"
        >
          <b-img
            src="/admin/images/file/action/rename.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>重新命名</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          class="dropdown-item-area"
          @click="submitDeleteFolder"
        >
          <b-img
            src="/admin/images/file/action/trash-bin.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>刪除</span>
        </v-contextmenu-item>

        <v-contextmenu-item
          class="dropdown-item-area"
          @click="submitSelectedFolder(rightClick)"
        >
          <b-img
            src="/admin/images/file/action/check1.svg"
            class="dropdown-item-btn-image mr-50"
            rounded
          />
          <span>
            <span>{{ isFolderInSelected(rightClick) ? '取消選取' : '選取檔案' }}</span>
          </span>
        </v-contextmenu-item>

      </div>
    </v-contextmenu>

    <!-- 編輯提示彈窗 -->
    <b-modal
      id="folderEditModal"
      no-close-on-backdrop
      centered
      hide-header
    >
      <div class="p-1">
        <h4>是否將{{ adminFolderState.renameFolderData.type === 'folder' ? '資料夾' : '檔案' }}的名稱變更為 {{ adminFolderState.renameFolderData.newName }} 嗎?</h4>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="closeEditModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isBusy"
            @click="confirmEditModal"
          >
            確認
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- 刪除提示彈窗 -->
    <b-modal
      id="folderDeleteModal"
      no-close-on-backdrop
      centered
      hide-header
    >
      <div class="p-1">
        <h4>你想要刪除{{ rightClick.type === 'folder' ? '資料夾' : '檔案' }} {{ rightClick.name }} 嗎?</h4>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="closeDeleteModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isBusy"
            @click="confirmDeleteModal"
          >
            刪除
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- <div>當前資料夾路徑:
      <ul>
        <li
          v-for="item in adminFolderState.currentLocalPath"
          :key="item.id"
        >
          {{ item.id }}.{{ item.name }} ({{ item.parent_folder_id }})
        </li>
      </ul>
    </div>
    <div>歷史路徑:
      <ul>
        <li
          v-for="item in adminFolderState.historyFolderPath"
          :key="item.id"
        >
          {{ item.id }}.{{ item.name }} ({{ item.parent_folder_id }})
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import {
  BImg, BButton, BModal, BFormInput,
} from 'bootstrap-vue'
import FolderImage from './FolderImage.vue'
import { useFolderList, useFolderSetting, useFolderMixins } from '../useFolder'

export default {
  components: {
    BImg,
    BButton,
    BModal,
    BFormInput,

    FolderImage,
  },
  mixins: [useFolderMixins],
  data() {
    return {
      hoverItemId: null,
      rightClick: {},
      clickFolderTimeout: null, // 用于记录第一次点击后的超时时间
      clickNameTimeout: null,
    }
  },
  computed: {
    adminFolderState() {
      return this.$store.state['admin-folder']
    },
    currentFolderFilter() {
      const adminFolderState = this.$store.state['admin-folder']
      const searchKeyword = adminFolderState.searchQuery ? adminFolderState.searchQuery.toLowerCase() : adminFolderState.searchQuery
      if (searchKeyword) return adminFolderState.currentFolder.filter(f => !f.name || f.name.includes(searchKeyword.toLowerCase()))
      return adminFolderState.currentFolder
    },
  },
  methods: {
    // {{ adminFolderState.searchQuery }}
    // (顯示)檔案字數
    resolveFileNameLong(text, length) {
      if (text.length > length * 2) {
        const start = text.slice(0, length)
        const end = text.slice(-6)
        return `${start}...${end}`
      }
      return text
    },
    // (測試)圖片網址(未使用)
    testImageUrl(item) {
      const img = new Image()
      img.src = this.resolveFileTypeImage(item).image

      return new Promise(resolve => {
        img.onload = () => {
          resolve(true)
        }

        img.onerror = () => {
          resolve(false)
        }
      })
    },

    // (顯示)檔案類型
    resolveFileTypeImage(item) {
      const matchingItem = this.folderType.find(el => el.type === item.extension)
      if (item.type === 'file' && item.preview_image) {
        matchingItem.image = item.preview_image
      }
      if (matchingItem) return matchingItem
      return { name: '未知', type: null, image: '/admin/images/file/type/empty.svg' }
    },

    // (是否)選取
    isFolderInSelected(item) {
      const { selectedMutiFolders } = this.adminFolderState
      const index = selectedMutiFolders.findIndex(el => el.id === item.id && el.type === item.type)

      if (index !== -1) return true
      return false
    },

    // (觸發)選取檔案
    submitSelectedFolder(item) {
      this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', null)
      this.$store.commit('admin-folder/SELECTED_MUTI_FOLDERS', item)
    },

    setFocusFolderData(event, item) {
      event.stopPropagation()
      if (this.adminFolderState.selectedMutiFolders.length > 0) return
      this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', item)
    },

    // (雙擊)打開資料夾
    handleClickOpenFolder(item) {
      if (this.clickFolderTimeout && this.adminFolderState.focusFolderData.id === item.id) {
        clearTimeout(this.clickFolderTimeout)
        this.clickFolderTimeout = null
        if (this.adminFolderState.focusFolderData.type === 'folder') {
          this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', null)
          this.getOpenFolderChildren(item, () => {
            // this.resetComponentData()
            this.$store.commit('admin-folder/OPEN_LOCAL_FOLDER', item)
            this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
          })
        }
      } else {
        this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', item)
        this.clickFolderTimeout = setTimeout(() => {
          this.clickFolderTimeout = null
        }, 300)
      }
    },

    // (雙擊)編輯名稱
    handleClickEditName(item) {
      if (this.clickNameTimeout && (this.adminFolderState.focusFolderData && this.adminFolderState.focusFolderData.id === item.id)) {
        clearTimeout(this.clickNameTimeout)
        const nameData = {
          id: item.id,
          type: item.type,
          newName: item.name,
        }
        this.$store.commit('admin-folder/UPDATE_RENAME_FOLDER_STATE', nameData)
      } else {
        this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', item)
        this.clickNameTimeout = setTimeout(() => {
          this.clickNameTimeout = null
        }, 300)
      }
    },
    // ------------------------------------------------------------------------------------------
    // 下載
    // ------------------------------------------------------------------------------------------
    // (觸發)下載
    submitDownloadFile(rightClick) {
      this.getFileDownload(rightClick, url => {
        const link = document.createElement('a')
        link.href = url
        link.click()
      })
    },

    // (觸發)顯示詳情
    submitShowFileInfo(rightClick) {
      this.$store.commit('admin-folder/UPDATE_FOCUS_FOLDER_STATE', rightClick)
      this.$emit('submit-open-application-info')
    },
    // ------------------------------------------------------------------------------------------
    // 編輯
    // ------------------------------------------------------------------------------------------
    // (點擊動作)重新命名
    submitRenameFolder() {
      const nameData = {
        id: this.rightClick.id,
        type: this.rightClick.type,
        newName: this.rightClick.name,
      }
      this.$store.commit('admin-folder/UPDATE_RENAME_FOLDER_STATE', nameData)
    },

    // (觸發)編輯名稱
    submitConfirmName() {
      if (!this.adminFolderState.renameFolderData.id) {
        this.adminFolderState.renameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
        return
      }
      this.$bvModal.show('folderEditModal')
    },

    // (觸發)關閉編輯
    closeEditModal() {
      // this.adminFolderState.renameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
      // this.$bvModal.hide('folderEditModal')
      const resolveRenameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
      this.$bvModal.hide('folderEditModal')
      this.$store.commit('admin-folder/UPDATE_RENAME_FOLDER_STATE', resolveRenameFolderData)
    },

    // (編輯)檔案
    confirmEditModal() {
      this.isBusy = true
      this.setFolderUpdate(() => {
        const resolveListData = this.adminFolderState.currentFolder.map(item => {
          if (item.id === this.adminFolderState.renameFolderData.id && item.type === this.adminFolderState.renameFolderData.type) {
            return {
              ...item,
              name: this.adminFolderState.renameFolderData.newName,
              updated_at: new Date(),
            }
          }
          return item
        })

        // (修改)側欄結構
        if (this.adminFolderState.renameFolderData.type === 'folder') {
          this.refreshSidebarList('update', { ...this.adminFolderState.renameFolderData })
        }

        // (修改)當前資料夾
        this.$store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)
        this.isBusy = false
        this.closeEditModal()
        this.useAlertToast(true, '更新成功')
      })
    },

    // ------------------------------------------------------------------------------------------
    // 刪除
    // ------------------------------------------------------------------------------------------
    // (觸發)刪除檔案
    submitDeleteFolder() {
      if (this.adminFolderState.selectedMutiFolders.length > 0) {
        this.submitSelectedFolder(this.rightClick)
        return
      }
      this.$bvModal.show('folderDeleteModal')
    },

    // (觸發)關閉備註
    closeDeleteModal() {
      this.rightClick = {}
      this.$bvModal.hide('folderDeleteModal')
    },

    // (刪除)檔案
    confirmDeleteModal() {
      this.isBusy = true

      this.setFolderDelete(this.rightClick.type, [this.rightClick.id], () => {
        // (修改)側欄結構
        if (this.rightClick.type === 'folder') {
          this.refreshSidebarList('delete', { id: this.rightClick.id })
        }

        // (修改)當前資料夾
        let resolveListData = JSON.parse(JSON.stringify(this.adminFolderState.currentFolder))
        resolveListData = resolveListData.filter(item => !(item.id === this.rightClick.id && item.type === this.rightClick.type))
        this.$store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)
        this.isBusy = false
        this.closeDeleteModal()
      })
    },

    // (重設)組件
    resetComponentData() {
      this.adminFolderState.renameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
    },

    // (觸發)重新整理
    submitRefreshFolder() {
      if (this.adminFolderState.isBusyLoading) return
      if (!this.currentLocalFolder) {
        // this.getFolderListInitData()
        this.getOpenFolderChildren(null, () => {
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
        })
      } else {
        this.getOpenFolderChildren(this.currentLocalFolder, () => {
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
        })
      }
    },
  },
  setup() {
    const {
      isBusy,
      blankEditData,
      useAlertToast,
      getOpenFolderChildren,
      getFolderListInitData,
      refreshSidebarList,
      setFolderUpdate,
      setFolderDelete,
      getFileDownload,
    } = useFolderList()

    const {
      folderType,
    } = useFolderSetting()

    return {
      folderType,
      isBusy,
      blankEditData,
      useAlertToast,
      getFolderListInitData,
      refreshSidebarList,
      getOpenFolderChildren,
      setFolderUpdate,
      setFolderDelete,
      getFileDownload,
    }
    // getTodoListInitData()
  },
}
</script>

<style lang="scss" scoped>
.dropdown-item-area {
  transition: transform ease-out 200ms;
  font-size: 14px;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}
</style>

<style lang="scss" scoped>
.gallery-area {
  display: flex;
  flex-flow: row wrap;
  // height: 100%;
  margin: 0 auto;
  align-items: flex-start;
  // justify-content: space-between;
  padding: 20px 10px;
  // background-color: red;
}
.thumbnail {
  margin: 10px;
  position: relative;
  // padding: 5px;
  display: flex;
  height: 120px;
  max-height: 150px;
  min-width: 100px;
  max-width: 80px;
  cursor: pointer;
  flex-basis: 20%;
  margin-bottom: 10px;
  align-content: center;
  align-items: center;
  justify-content: start;
  flex-flow: column nowrap;
  text-align: center;
  // color: #6f6e6e;
  font-weight: 300;
  font-size: 1em;
  // transition: background 500ms linear;
  .thumbnail-image {
    // background-color: rgb(251, 178, 178);
    width: 100%;
    padding: 5px;
  }
  .title {
    // background-color: rgb(134, 193, 223);
    margin-top: 10px;
    // background-color:#00000010;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 100%;
    max-width: 80px;
    height: 40px;
  }

  // .folder {
  //   position: relative;
  //   height: 40%;
  //   width: 55%;
  //   margin-top: auto;
  //   background: #ff9a47;
  //   border-radius: 0 4px 0 0;
  //   transition: background 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 150ms;
  //   &:after {
  //     transform: scaleY(1) skewX(-2deg);
  //     border-radius: 4px 4px 0 0;
  //     transform-origin: bottom left;
  //     background: #ffbf59;
  //     transition: all 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 150ms;
  //   }

  //   &:before {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     transform: translateY(-50%);
  //     height: 10px;
  //     width: 45%;
  //     background: #ffa947;
  //     border-radius: 2px 6px 0 0;
  //     transition: background 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 150ms;
  //   }

  //   .file {
  //     transform: scale(0.93, 0.94) skewX(-2deg) translate(-2px, 0);
  //     &:before {
  //       content: '';
  //       transform: scale(1, 0.95) skewX(-3deg) translate(1px, 0);
  //       transition: all 250ms cubic-bezier(0.77, 0, 0.175, 1) 250ms;
  //     }

  //     &:after {
  //       content: '';
  //       transform: scale(1, 0.88) skewX(-4deg) translate(3px, 0);
  //     }
  //   }

  //   .file, .file:after, .file:before {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     display: block;
  //     width: 100%;
  //     height: 100%;
  //     background: #fff;
  //     box-shadow: -1px -1px 1px #ff73164d;
  //   }
  // }

  .thumbnail-content-selected-area {
    background-color: #24c8ff22;
    border-radius: 5px;
  }
  .thumbnail-mask {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
  }

  .component-folder-image {
    img {
      max-height: 50px;
      width: 100%;
      max-width: 60px;
      // user-select: none;
    }
  }
}
.thumbnail-hover {
  background-color:#adadad30;
  border-radius: 10px;
}

.thumbnail-focus {
  background-color:#adadad30;
  border-radius: 10px;
  border: 1px solid rgba(202, 202, 202, 0.563);
}

// .folder:after, .folder:before {
//   content: '';
//   display: block;
//   width: 100%;
//   height: 100%;
// }

// .thumbnail:hover .folder,
// .thumbnail:hover .folder:before {
//   background: #ffb86c;
// }
// .thumbnail:hover .folder:after {
//   transform: scaleY(0.9) skewX(-6deg);
//   background: #ffa947;
// }
// .thumbnail:hover .folder .file:before {
//   transform: scale(1, 0.95) skewX(-2deg) translate(10px, -20%) rotate(10deg);
//   box-shadow: -1px 1px 1px #ff73164d;
//   border: 1px solid #ff73164d;
// }

</style>
