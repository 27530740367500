<template>
  <div class="folder-application-header">
    <div class="header-title header-border-bottom">
      {{ currentLocalFolder ? currentLocalFolder.name : '個人雲端' }}
      <!-- <b-img
        src="/admin/images/common/info.svg"
        class="header-title-image ml-25"
        :draggable="false"
        rounded
        @click="$emit('open-application-info')"
      /> -->
      <!-- {{ adminFolderState.displayType }} -->
      <!-- / {{ adminFolderState.activeFolderContactId }} -->
    </div>

    <div class="header-path header-border-bottom">
      <div class="path-bar">
        <!-- 回上層 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title="adminFolderState.currentLocalPath.length <= 0 ? null : '回上層'"
          class="path-icon-btn-sm"
          :class=" { 'path-icon-disable': adminFolderState.currentLocalPath.length <= 0 || adminFolderState.isBusyLoading }"
          @click="submitPreviousFolder"
        >
          <b-img
            src="/admin/images/file/action/previous.svg"
            class="path-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <!-- 回下層 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title="adminFolderState.historyFolderPath.length <= 0 ? null : '回下層'"
          class="path-icon-btn-sm d-none d-sm-block"
          :class=" { 'path-icon-disable': adminFolderState.historyFolderPath.length <= 0 || adminFolderState.isBusyLoading }"
          @click="submitNextFolder"
        >
          <!-- path-icon-disable -->
          <b-img
            src="/admin/images/file/action/next.svg"
            class="path-icon-btn-image"
            :draggable="false"
            rounded
          />
          <!-- {{ adminFolderState.historyFolderPath }} -->
        </div>

        <!-- 回根節點 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title="!adminFolderState.activeFolderContactId ? null : '回根節點'"
          class="path-icon-btn-sm d-none d-sm-block"
          :class=" { 'path-icon-disable': !adminFolderState.activeFolderContactId || adminFolderState.isBusyLoading }"
          @click="submitRootFolder"
        >
          <b-img
            src="/admin/images/file/action/root.svg"
            class="path-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <!-- 重新整理 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title="adminFolderState.isBusyLoading ? null : '重新整理'"
          class="path-icon-btn-sm"
          :class=" { 'path-icon-disable': adminFolderState.isBusyLoading }"
          @click="submitRefreshFolder"
        >
          <b-img
            src="/admin/images/file/action/restart.svg"
            class="path-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <div class="icon-border" />
      </div>

      <div class="path-route">
        <div class="path-route-breadcrumb">
          <div class="breadcrumb-root">
            <!-- <feather-icon
              icon="CloudIcon"
              size="16"
              class="mx-50"
              :class=" { 'root-icon': adminFolderState.activeFolderContactId && !adminFolderState.isBusyLoading }"
              @click="submitRootFolder"
            /> -->
            <div
              class="path-icon-btn-sm"
              :class=" { 'path-icon-disable': !adminFolderState.activeFolderContactId || adminFolderState.isBusyLoading }"
              @click="submitRootFolder"
            >
              <b-img
                src="/admin/images/file/action/root-computer.svg"
                class="path-icon-btn-image"
                :draggable="false"
                rounded
              />
            </div>

            <feather-icon
              icon="ChevronRightIcon"
              size="16"
            />
          </div>

          <div
            v-for="(path, index) in adminFolderState.currentLocalPath"
            :key="`path-${index + 1}`"
          >
            <div
              v-if="adminFolderState.currentLocalPath.length === index + 1"
              class="breadcrumb-name-active"
            >
              {{ path.name }}
            </div>

            <div
              v-else
              class="breadcrumb-name"
              @click="submitToPreviousFolder(path, index)"
            >
              {{ path.name }}
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              size="16"
            />
          </div>
        </div>
      </div>

      <div class="path-search ">
        <div class="icon-border" />

        <div>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="adminFolderState.searchQuery"
              debounce="500"
              class="d-inline-block"
              placeholder="搜尋..."
            />
            <!-- v-model="searchQuery" -->
            <!--  -->
            <b-input-group-append
              v-if="adminFolderState.searchQuery"
              is-text
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer text-muted"
                @click="adminFolderState.searchQuery = null"
              />
              <!-- @click="searchQuery = null" -->
            </b-input-group-append>
          </b-input-group>
        </div>

      </div>
    </div>

    <div class="header-action">
      <div class="action-bar">

        <!-- 側欄 -->
        <div class="d-flex align-items-center d-lg-none d-block">
          <div
            class="actions-icon-btn-sm"
            @click="$emit('open-application-sidebar')"
          >
            <b-img
              src="/admin/images/file/action/list.svg"
              class="actions-icon-btn-image"
              :draggable="false"
              rounded
            />
          </div>

          <!-- 新增 -->
          <div
            class="actions-icon-btn-sm"
            @click="$bvModal.show('upload-explorer-modal')"
          >
            <b-img
              src="/admin/images/file/action/plus.svg"
              class="actions-icon-btn-image"
              :draggable="false"
              rounded
            />
          </div>

          <div class="icon-border" />
        </div>

        <!-- 新增 -->
        <div class="d-flex align-items-center d-none d-lg-block">
          <div
            class="actions-icon-btn-sm d-none d-lg-block"
            @click="$bvModal.show('upload-explorer-modal')"
          >
            <b-img
              src="/admin/images/file/action/plus.svg"
              class="actions-icon-btn-image"
              :draggable="false"
              rounded
            />
            <span class="text-primary actions-icon-btn-title">新增</span>
          </div>
        </div>

        <div class="icon-border d-none d-lg-block" />

        <!-- 顯示詳情 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title=" (adminFolderState.focusFolderData === null || adminFolderState.selectedMutiFolders.length > 0) || adminFolderState.isBusyLoading ? null : '顯示詳情'"
          class="actions-icon-btn-sm d-none d-sm-block"
          :class=" { 'actions-icon-disable': (adminFolderState.focusFolderData === null || adminFolderState.selectedMutiFolders.length > 0) || adminFolderState.isBusyLoading }"
          @click="$emit('open-application-info')"
        >
          <b-img
            src="/admin/images/common/info.svg"
            class="actions-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <!-- 剪下 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title="(adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) ? null : '剪下'"
          class="actions-icon-btn-sm d-none d-sm-block"
          :class=" { 'actions-icon-disable': (adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) || adminFolderState.isBusyLoading }"
          @click="submitCutFolder"
        >
          <b-img
            src="/admin/images/file/action/scissors-1.svg"
            class="actions-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <!-- 複製 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title="(adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) ? null : '複製'"
          class="actions-icon-btn-sm  d-none d-sm-block"
          :class=" { 'actions-icon-disable': (adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) || adminFolderState.isBusyLoading }"
          @click="submitCopyFolder"
        >
          <b-img
            src="/admin/images/file/action/copy-1.svg"
            class="actions-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <!-- 貼上 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title="adminFolderState.copySelectedFolders.length <= 0 ? null : '貼上'"
          class="actions-icon-btn-sm  d-none d-sm-block"
          :class=" { 'actions-icon-disable': adminFolderState.copySelectedFolders.length <= 0 || adminFolderState.isBusyLoading }"
          @click="submitPasteFolder"
        >
          <b-img
            src="/admin/images/file/action/paste-1.svg"
            class="actions-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <!-- 重新命名 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title="adminFolderState.focusFolderData === null ? null : '重新命名'"
          class="actions-icon-btn-sm  d-none d-sm-block"
          :class=" { 'actions-icon-disable': (adminFolderState.focusFolderData === null || adminFolderState.selectedMutiFolders.length > 0) || adminFolderState.isBusyLoading }"
          @click="submitRenameFolder"
        >
          <b-img
            src="/admin/images/file/action/rename-1.svg"
            class="actions-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <!-- 刪除 -->
        <div
          v-b-tooltip.hover.v-secondary
          :title="(adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) ? null : '刪除'"
          class="actions-icon-btn-sm  d-none d-sm-block"
          :class=" { 'actions-icon-disable': (adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) || adminFolderState.isBusyLoading }"
          @click="submitDeleteFolder"
        >
          <b-img
            src="/admin/images/file/action/trash-bin-1.svg"
            class="actions-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <!-- 下載 -->
        <div
          v-if="adminFolderState.focusFolderData && adminFolderState.focusFolderData.type !== 'folder'"
          v-b-tooltip.hover.v-secondary
          :title="(adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) ? null : '下載'"
          class="actions-icon-btn-sm"
          :class=" { 'actions-icon-disable': (adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) || adminFolderState.isBusyLoading }"
          @click="submitDownloadFolder"
        >
          <b-img
            src="/admin/images/file/action/download-1.svg"
            class="actions-icon-btn-image"
            :draggable="false"
            rounded
          />
        </div>

        <!-- <div class="icon-border d-block d-sm-none" /> -->

        <div class="d-block d-sm-none">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
          >
            <template #button-content>
              <div
                v-b-tooltip.hover.v-secondary
                title="更多"
                class="actions-link-btn-sm"
              >
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="17"
                  class="align-middle text-body"
                />
              </div>
            </template>

            <b-dropdown-item
              class="dropdown-item-area animate__animated animate__fadeIn"
              :disabled="(adminFolderState.focusFolderData === null || adminFolderState.selectedMutiFolders.length > 0) || adminFolderState.isBusyLoading"
              @click="$emit('open-application-info')"
            >
              <b-img
                src="/admin/images/common/info.svg"
                class="dropdown-item-btn-image mr-50"
                rounded
              />
              <span>顯示詳情</span>
            </b-dropdown-item>

            <b-dropdown-item
              class="dropdown-item-area animate__animated animate__fadeIn"
              :disabled="(adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) || adminFolderState.isBusyLoading"
            >
              <b-img
                src="/admin/images/file/action/scissors-1.svg"
                class="dropdown-item-btn-image mr-50"
                rounded
              />
              <span>剪下</span>
            </b-dropdown-item>

            <b-dropdown-item
              class="dropdown-item-area animate__animated animate__fadeIn"
              :disabled="(adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) || adminFolderState.isBusyLoading"
            >
              <b-img
                src="/admin/images/file/action/copy-1.svg"
                class="dropdown-item-btn-image mr-50"
                rounded
              />
              <span>複製</span>
            </b-dropdown-item>

            <b-dropdown-item
              class="dropdown-item-area animate__animated animate__fadeIn"
              :disabled="adminFolderState.copySelectedFolders.length <= 0 || adminFolderState.isBusyLoading"
            >
              <b-img
                src="/admin/images/file/action/paste-1.svg"
                class="dropdown-item-btn-image mr-50"
                rounded
              />
              <span>貼上</span>
            </b-dropdown-item>

            <b-dropdown-item
              class="dropdown-item-area animate__animated animate__fadeIn"
              :disabled="(adminFolderState.focusFolderData === null || adminFolderState.selectedMutiFolders.length > 0) || adminFolderState.isBusyLoading"
              @click="submitRenameFolder"
            >
              <b-img
                src="/admin/images/file/action/rename-1.svg"
                class="dropdown-item-btn-image mr-50"
                rounded
              />
              <span>重新命名</span>
            </b-dropdown-item>

            <b-dropdown-item
              class="dropdown-item-area animate__animated animate__fadeIn"
              :disabled="(adminFolderState.focusFolderData === null && adminFolderState.selectedMutiFolders.length <= 0) || adminFolderState.isBusyLoading"
              @click="submitDeleteFolder"
            >
              <b-img
                src="/admin/images/file/action/trash-bin-1.svg"
                class="dropdown-item-btn-image mr-50"
                rounded
              />
              <span>刪除</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div
          v-if="adminFolderState.renameFolderData.id"
          class="d-flex align-items-center"
        >
          <div class="icon-border" />

          <!-- 確認 -->
          <div
            v-b-tooltip.hover.v-secondary
            :title="adminFolderState.focusFolderData === null ? null : '確認'"
            class="actions-icon-btn-sm"
            @click="submitConfirmName"
          >
            <b-img
              src="/admin/images/file/action/completed.svg"
              class="actions-icon-btn-image"
              :draggable="false"
              rounded
            />
          </div>

          <!-- 取消 -->
          <div
            v-b-tooltip.hover.v-secondary
            :title="adminFolderState.focusFolderData === null ? null : '取消'"
            class="actions-icon-btn-sm"
            @click="closeEditModal"
          >
            <b-img
              src="/admin/images/file/action/error.svg"
              class="actions-icon-btn-image"
              :draggable="false"
              rounded
            />
          </div>
        </div>
      </div>

      <div v-if="adminFolderState.selectedMutiFolders.length > 0">
        <div class="d-flex align-items-center">
          <b-button
            variant="flat-primary"
            type="submit"
            size="sm"
            class="text-nowrap cancel-btn"
            @click="() => {
              $store.commit('admin-folder/SELECTED_MUTI_FOLDERS_CANCEL')
            }"
          >
            取消
          </b-button>
        </div>
      </div>

      <!-- {{ adminFolderState.selectedMutiFolders }} -->
    </div>

    <!-- 編輯提示彈窗 -->
    <b-modal
      id="folderHeaderEditModal"
      no-close-on-backdrop
      centered
      hide-header
    >
      <div class="p-1">
        <h4>是否將{{ adminFolderState.renameFolderData.type === 'folder' ? '資料夾' : '檔案' }}的名稱變更為 {{ adminFolderState.renameFolderData.newName }} 嗎?</h4>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="closeEditModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isBusy"
            @click="confirmEditModal"
          >
            確認
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- 刪除提示彈窗 -->
    <b-modal
      id="folderHeaderDeleteModal"
      no-close-on-backdrop
      centered
      hide-header
    >
      <div
        v-if="deleteArray.type"
        class="p-1"
      >
        <h4 v-if="deleteArray.type === 'single'">
          你想要刪除{{ deleteArray.data.type === 'folder' ? '資料夾' : '檔案' }} {{ deleteArray.data.name }} 嗎?
        </h4>

        <h4 v-if="deleteArray.type === 'muti'">
          你想要刪除所選到的 {{ deleteArray.data.length }} 個資料夾或檔案嗎?
        </h4>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="closeDeleteModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isBusy"
            @click="confirmDeleteModal"
          >
            刪除
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip, BImg, BDropdown, BDropdownItem, BButton, BModal, BInputGroup, BInputGroupAppend,
  BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import { useFolderList } from '../useFolder'

export default {
  components: {
    BImg,
    BModal,
    BButton,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      deleteArray: JSON.parse(JSON.stringify(this.blankDeleteArray)),
    }
  },
  computed: {
    adminFolderState() {
      return this.$store.state['admin-folder']
    },
    currentLocalFolder() {
      const currentLocal = JSON.parse(JSON.stringify(this.adminFolderState.currentLocalPath))
      if (currentLocal.length > 0) {
        const lastElement = currentLocal.pop()
        return lastElement
      }
      return null
    },
  },
  methods: {
    // (觸發)回根結點
    submitRootFolder() {
      if (!this.adminFolderState.activeFolderContactId) return
      if (this.adminFolderState.isBusyLoading) return
      this.getOpenFolderChildren({}, () => {
        this.$store.commit('admin-folder/ROOT_LOCAL_FOLDER')
        this.$store.commit('admin-folder/SELECTED_MUTI_FOLDERS_CANCEL')
        this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
      })
    },

    // (觸發)回上層
    submitPreviousFolder() {
      if (this.adminFolderState.currentLocalPath.length <= 0) return
      if (this.adminFolderState.isBusyLoading) return

      if (this.adminFolderState.currentLocalPath.length === 1) {
        this.getOpenFolderChildren({}, () => {
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
          this.$store.commit('admin-folder/PREVIOUS_LOCAL_FOLDER')
        })
      }

      if (this.adminFolderState.currentLocalPath.length > 1) {
        const copyPath = JSON.parse(JSON.stringify(this.adminFolderState.currentLocalPath))
        const lastElement = copyPath[this.adminFolderState.currentLocalPath.length - 2]

        this.getOpenFolderChildren(lastElement, () => {
          this.$store.commit('admin-folder/PREVIOUS_LOCAL_FOLDER')
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
        })
      }
    },

    // (觸發)回下層
    submitNextFolder() {
      if (this.adminFolderState.historyFolderPath.length <= 0) return
      if (this.adminFolderState.isBusyLoading) return
      const historyFolderPath = JSON.parse(JSON.stringify(this.adminFolderState.historyFolderPath))
      if (historyFolderPath.length > 0) {
        const lastHistoryFolderPath = historyFolderPath[this.adminFolderState.historyFolderPath.length - 1]
        this.getOpenFolderChildren(lastHistoryFolderPath, () => {
          this.$store.commit('admin-folder/NEXT_LOCAL_FOLDER')
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
        })
      }
    },

    // (觸發)重新整理
    submitRefreshFolder() {
      if (this.adminFolderState.isBusyLoading) return
      if (!this.currentLocalFolder) {
        this.getOpenFolderChildren(null, () => {
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
        })
      } else {
        this.getOpenFolderChildren(this.currentLocalFolder, () => {
          this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
        })
      }
    },
    // ------------------------------------------------------------------------------------
    // (直接)跳轉
    submitToPreviousFolder(path, index) {
      const currentLocalPathLength = (this.adminFolderState.currentLocalPath.length - 1)
      this.getOpenFolderChildren(path, () => {
        if (this.adminFolderState.currentLocalPath.length > 1) {
          for (let i = index; i < currentLocalPathLength; i += 1) {
            this.$store.commit('admin-folder/PREVIOUS_LOCAL_FOLDER')
          }
        }
        this.$store.commit('admin-folder/UPDATE_BUSY_LOADING_STATE', false)
      })
    },

    // (觸發)多選剪下
    submitCutFolder() {
      if (this.adminFolderState.focusFolderData) {
        // this.adminFolderState.focusFolderData
        this.useAlertToast(false, '剪下功能尚未開放')
      }

      if (this.adminFolderState.selectedMutiFolders.length > 0) {
        // this.adminFolderState.selectedMutiFolders
        this.useAlertToast(false, '多選剪下功能尚未開放')
      }
    },

    // (觸發)多選複製
    submitCopyFolder() {
      if (this.adminFolderState.focusFolderData) {
        // this.adminFolderState.focusFolderData
        this.useAlertToast(false, '複製功能尚未開放')
      }

      if (this.adminFolderState.selectedMutiFolders.length > 0) {
        // this.adminFolderState.selectedMutiFolders
        this.useAlertToast(false, '多選複製功能尚未開放')
      }
    },

    // (觸發)重新命名
    submitRenameFolder() {
      if (this.adminFolderState.focusFolderData) {
        const nameData = {
          id: this.adminFolderState.focusFolderData.id,
          type: this.adminFolderState.focusFolderData.type,
          newName: this.adminFolderState.focusFolderData.name,
        }
        this.$store.commit('admin-folder/UPDATE_RENAME_FOLDER_STATE', nameData)
      }
    },

    // (觸發)貼上
    submitPasteFolder() {
      this.useAlertToast(false, '貼上功能尚未開放')
    },

    // (觸發)下載
    submitDownloadFolder() {
      this.getFileDownload(this.adminFolderState.focusFolderData, url => {
        const link = document.createElement('a')
        link.href = url
        link.click()
      })
    },
    // ------------------------------------------------------------------------------------------
    // 編輯
    // ------------------------------------------------------------------------------------------
    // (觸發)編輯名稱
    submitConfirmName() {
      if (!this.adminFolderState.renameFolderData.id) {
        this.adminFolderState.renameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
        return
      }
      this.$bvModal.show('folderHeaderEditModal')
    },

    // (觸發)關閉編輯
    closeEditModal() {
      const resolveRenameFolderData = JSON.parse(JSON.stringify(this.blankEditData))
      this.$bvModal.hide('folderHeaderEditModal')
      this.$store.commit('admin-folder/UPDATE_RENAME_FOLDER_STATE', resolveRenameFolderData)
    },

    // (編輯)檔案
    confirmEditModal() {
      this.isBusy = true
      this.setFolderUpdate(() => {
        const resolveListData = this.adminFolderState.currentFolder.map(item => {
          if (item.id === this.adminFolderState.renameFolderData.id && item.type === this.adminFolderState.renameFolderData.type) {
            return {
              ...item,
              name: this.adminFolderState.renameFolderData.newName,
            }
          }
          return item
        })

        // (修改)側欄結構
        if (this.adminFolderState.renameFolderData.type === 'folder') {
          this.refreshSidebarList('update', { ...this.adminFolderState.renameFolderData })
        }

        // // (修改)當前資料夾
        this.$store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)
        this.isBusy = false
        this.closeEditModal()
        this.useAlertToast(true, '更新成功')
      })
    },

    // -----------------------------------------------------------------------------
    // 刪除
    // -----------------------------------------------------------------------------
    // (觸發)刪除
    submitDeleteFolder() {
      if (this.adminFolderState.focusFolderData) {
        this.deleteArray.type = 'single'
        this.deleteArray.data = this.adminFolderState.focusFolderData
        setTimeout(() => this.$bvModal.show('folderHeaderDeleteModal'), 200)
      }

      if (this.adminFolderState.selectedMutiFolders.length > 0) {
        this.deleteArray.type = 'muti'
        this.deleteArray.data = JSON.parse(JSON.stringify(this.adminFolderState.selectedMutiFolders))
        setTimeout(() => this.$bvModal.show('folderHeaderDeleteModal'), 200)
      }
    },

    // (觸發)關閉刪除
    closeDeleteModal() {
      this.$bvModal.hide('folderHeaderDeleteModal')
    },

    // (刪除)檔案
    confirmDeleteModal() {
      this.isBusy = true
      if (this.deleteArray.type === 'single') {
        const arrayId = []
        arrayId.push(this.deleteArray.data.id)

        this.setFolderDelete(this.deleteArray.data.type, [this.deleteArray.data.id], () => {
          // (修改)側欄結構
          if (this.deleteArray.data.type === 'folder') {
            this.refreshSidebarList('delete', { id: this.deleteArray.data.id })
          }

          // (修改)當前資料夾
          let resolveListData = JSON.parse(JSON.stringify(this.adminFolderState.currentFolder))
          resolveListData = resolveListData.filter(item => !(item.id === this.deleteArray.data.id && item.type === this.deleteArray.data.type))
          this.$store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)
          this.isBusy = false
          this.closeDeleteModal()
          this.deleteArray = JSON.parse(JSON.stringify(this.blankDeleteArray))
        })
      }

      if (this.deleteArray.type === 'muti') {
        const folderArray = []
        const fileArray = []
        this.deleteArray.data.forEach(item => {
          if (item.type === 'folder') folderArray.push(item.id)
          if (item.type === 'file') fileArray.push(item.id)
        })

        this.setFolderMutiDelete(folderArray, fileArray, () => {
          // this.submitRefreshFolder()
          // (修改)側欄結構
          this.refreshSidebarList('delete-muti', { array: folderArray })
          // let resolveListData = JSON.parse(JSON.stringify(this.adminFolderState.currentFolder))
          // resolveListData = resolveListData.filter(item => (item.id !== this.rightClick.id && item.type !== this.rightClick.type))
          // this.$store.commit('admin-folder/UPDATE_CURRENT_FOLDER_LIST', resolveListData)

          // (修改)當前資料夾
          this.submitRefreshFolder()
          this.isBusy = false
          this.closeDeleteModal()
          this.deleteArray = JSON.parse(JSON.stringify(this.blankDeleteArray))
          this.$store.commit('admin-folder/SELECTED_MUTI_FOLDERS_CANCEL')
        })
      }
    },
  },
  setup() {
    const {
      isBusy,
      blankEditData,
      useAlertToast,
      getFolderListInitData,
      getOpenFolderChildren,
      setFolderDelete,
      setFolderUpdate,
      setFolderMutiDelete,
      getFileDownload,
      refreshSidebarList,
    } = useFolderList()

    const blankDeleteArray = {
      type: null,
      data: null,
    }

    return {
      isBusy,
      blankEditData,
      blankDeleteArray,
      useAlertToast,
      getFolderListInitData,
      getOpenFolderChildren,
      getFileDownload,
      setFolderDelete,
      setFolderUpdate,
      setFolderMutiDelete,
      refreshSidebarList,
    }
  },
}
</script>

<style lang="scss" scoped>
$folder-height: 40px;

.folder-application-header {
  .header-title {
    // background-color: rgb(232, 196, 250);
    line-height: 35px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .header-title-image {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }

  .header-path {
    line-height: $folder-height;
    padding: 0 10px;
    display: grid; /* 使用网格布局 */
    grid-template-columns: auto 3fr 150px;
    grid-template-areas: "bar route search";
    width: 100%;
    .path-bar {
      grid-area: bar; /* 定义区域 */
      height: $folder-height;
      line-height: 30px;
      display: flex;
      align-items: center;
    }

    .path-route {
      grid-area: route;
      line-height: 38px;
      display: flex;
      width: 100%;
      min-width: 60%;
      // background-color: rgba(249, 245, 223, 0.233);
      overflow-x: auto;
      // scrollbar-width: thin;
      // margin-bottom: -5px;
      &::-webkit-scrollbar {
        height: 4px;
      }

      .path-route-breadcrumb {
        display: flex;
        align-items: center;
        // min-width: 300px;
        line-height: 38px;
        white-space: nowrap;

        div {
          display: flex;
          align-items: center;
        }

        .breadcrumb-root {
          // .rooi
          .root-icon {
            cursor: pointer;
            color: #419fd9;
          }
        }

        .breadcrumb-name {
          margin: 0 5px;
          color: #419fd9;
          cursor: pointer;
        }

        .breadcrumb-name-active {
          margin: 0 5px;
        }
      }
    }

    .path-search {
      grid-area: search;
      height: $folder-height;
      line-height: 30px;
      display: flex;
      align-items: center;
      .input-group-text {
        border: none;
      }
      .form-control {
        border: none;
      }

      .input-group:not(.bootstrap-touchspin):focus-within {
        box-shadow: none;
      }
    }

    .icon-border {
      height: 40px;
      margin: 0 5px;
    }

    .path-icon-btn-sm {
      transition: transform ease-out 200ms;
      padding: 5px 8px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-right: 5px;
      line-height: 20px;
      .path-icon-btn-image {
        width: 18px;
        height: 18px;
      }
      .path-icon-btn-title {
        padding-left: 10px;
        white-space: nowrap;
        line-height: 20px;
        // font-size: 10px;
      }

      &:hover {
        cursor: pointer;
        background-color: #9de0f64f;
        .path-icon-btn-image {
          transform: scale(1.05);
          animation: btn-breathe .8s linear infinite;
        }
      }
    }

    .path-icon-disable {
      .path-icon-btn-image {
        opacity: 0.3;
      }
      &:hover {
        cursor: auto;
        background-color: transparent;
        .path-icon-btn-image {
          transform: none;
          animation: none;
        }
      }
    }
  }

  .header-action {
    // background-color: rgb(0, 255, 179);
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: $folder-height;
    padding: 0 10px;

    .action-bar {
      height: $folder-height;
      line-height: 30px;
      display: flex;
      align-items: center;
      .actions-icon-btn-sm {
        transition: transform ease-out 200ms;
        padding: 5px 8px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-right: 5px;
        line-height: 20px;
        .actions-icon-btn-image {
          width: 18px;
          height: 18px;
        }
        .actions-icon-btn-title {
          padding-left: 10px;
          white-space: nowrap;
          line-height: 20px;
          // font-size: 10px;
        }

        &:hover {
          cursor: pointer;
          background-color: #9de0f64f;
          .actions-icon-btn-image {
            transform: scale(1.05);
            animation: btn-breathe .8s linear infinite;
          }
        }
      }

      .actions-icon-disable {
        .actions-icon-btn-image {
          opacity: 0.2;
        }
        &:hover {
          cursor: auto;
          background-color: transparent;
          .actions-icon-btn-image {
            transform: none;
            animation: none;
          }
        }
      }

      .icon-border {
        // background-color: red;
        // width: 10px;
        height: 30px;
        margin: 5px;
      }

      .dropdown-item-area {
        line-height: 20px;
        transition: transform ease-out 200ms;
        .dropdown-item-btn-image {
          width: 14px;
          height: 14px;
        }

        &:hover {
          cursor: pointer;
          .dropdown-item-btn-image {
            transform: scale(1.2);
            animation: btn-breathe-todo-dropdown .8s linear infinite;
          }
        }

        @keyframes btn-breathe-todo-dropdown {
          0%{ transform: scale(0.8); }
          50%{ transform: scale(1.2); }
          100%{ transform: scale(0.8); }
        }
      }
    }

    // .cancel-btn {
    //   &:hover {
    //     background-color: #9de0f64f;
    //   }
    // }
  }
}
</style>
